<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <app-breadcrumb></app-breadcrumb>
    </div>

    <div class="container">
      <h5>{{ 'pages.default.responses.responses' | translate }} <span
        *ngIf="pathwayMonitor?.titleTranslationKey">-</span> {{ pathwayMonitor?.titleTranslationKey | translate }}</h5>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">
    @if (isLoading) {
      <app-page-loader></app-page-loader>
    } @else {
      <div class="response-detail monitor">
        <div class="row gx-5">
          <div class="col-4">
            <div class="patient-info">
              <div class="card p-2">
                <div class="d-flex align-items-center">
                  <app-avatar [profilePicture]="pathwayMonitor.patient?.profile_picture"></app-avatar>

                  <div class="details-wrapper m-start-2">
                    <p class="title">
                      {{ pathwayMonitor.patient.getFullName() }}
                    </p>

                    <p class="small text-muted font-weight-medium">
                      {{ pathwayMonitor.patient?.date_of_birth | timeZoneDate:dateFormat?.format }}
                    </p>
                  </div>

                  <a class="btn btn-tertiary btn-icon m-start-auto"
                     [routerLink]="['/patient/', pathwayMonitor.patient.uid, pathwayMonitor.patient_pathway_uid, 'query-lists']"
                     [queryParams]="{
                     filter: 'QUESTIONNAIRE',
                     'back-to-url': router.url
                     }">
                    <span [inlineSVG]="'./assets/svg/information-outline-24.svg'"
                          class="rtl-mirrored-inline-svg"></span>
                  </a>
                </div>

                <div class="d-flex mt-3 align-items-center">
                  <p class="text-truncate small text-muted font-weight-medium">
                    {{ pathwayMonitor.watches.length }} {{ 'pages.default.responses_detail.responses' | translate }}
                  </p>

                  <div class="watches d-inline-flex m-start-auto">
                    <div class="watch">
                      <span [inlineSVG]="'./assets/svg/watch-new.svg'"
                            class="rtl-mirrored-inline-svg watch-new"
                            [popover]="'pages.default.responses.monitor.new_watches' | translate"
                            triggers="mouseenter:mouseleave"></span>

                      <span class="watch-value">{{ pathwayMonitor.total_watches_open }}</span>
                    </div>

                    <div class="watch">
                      <span [inlineSVG]="'./assets/svg/watch-acknowledged.svg'"
                            class="rtl-mirrored-inline-svg watch-acknowledged"
                            [popover]="'pages.default.responses.monitor.acknowledged_watches' | translate"
                            triggers="mouseenter:mouseleave"></span>

                      <span class="watch-value">{{ pathwayMonitor.total_watches_acknowledged }}</span>
                    </div>

                    <div class="watch">
                      <span [inlineSVG]="'./assets/svg/watch-actioned.svg'"
                            class="rtl-mirrored-inline-svg watch-actioned"
                            [popover]="'pages.default.responses.monitor.actioned_watches' | translate"
                            triggers="mouseenter:mouseleave"></span>

                      <span class="watch-value">{{ pathwayMonitor.total_watches_actioned }}</span>
                    </div>
                  </div>
                </div>

                <hr>

                <div class="d-flex align-items justify-content-between flex-wrap mb-4">
                  <p class="small text-muted font-weight-medium">
                    {{ 'pages.default.responses_detail.pathway' | translate }}
                  </p>

                  <p class="font-weight-medium">
                    {{ pathwayMonitor.care_module.translationKey | translate }}
                  </p>
                </div>

                <div class="d-flex align-items justify-content-between flex-wrap mb-4">
                  <p class="small text-muted font-weight-medium">
                    {{ 'pages.default.responses_detail.surgery' | translate }}
                  </p>

                  @if (pathwayMonitor.surgery_date) {
                    <div class="text-align-end">
                      <p class="font-weight-medium">
                        {{ pathwayMonitor.surgery_date | timeZoneDate:dateFormat.format }}
                      </p>
                      <p class="font-weight-medium text-muted">
                        {{ daysSinceSurgery(pathwayMonitor) }}
                      </p>
                    </div>
                  } @else {
                    <p class="font-weight-medium">-</p>
                  }
                </div>

                <div class="d-flex align-items justify-content-between flex-wrap mb-4">
                  <p class="small text-muted font-weight-medium">
                    {{ 'pages.default.responses_detail.discharge' | translate }}
                  </p>

                  <div class="text-align-end">
                    @if (pathwayMonitor.discharge_date) {
                      <p class="font-weight-medium">
                        {{ pathwayMonitor.discharge_date | timeZoneDate:dateFormat.format }}
                      </p>

                      <p class="font-weight-medium text-muted">
                        {{ daysSinceDischarge(pathwayMonitor) }}
                      </p>
                    } @else {
                      <p class="font-weight-medium">-</p>
                    }

                  </div>
                </div>
              </div>

              <button type="button" (click)="logAction()" class="btn btn-primary w-100 mt-1"
                      [disabled]="pathwayMonitor.total_watches_acknowledged <= 0">
                <span class="mx-auto">{{ 'pages.default.responses_detail.log_action' | translate }}</span>
              </button>
            </div>
          </div>
          <div class="col-8">
            <div class="d-flex align-items-center mb-3">
              <h6 class="h-7 mb-0">{{ pathwayMonitor.titleTranslationKey | translate }}</h6>

              <button class="btn btn-primary m-start-auto"
                      [disabled]="pathwayMonitor.total_watches_open < 1"
                      [ngClass]="{'loader': isAcknowledging}"
                      (click)="acknowledgeWatches()">
                <span class="loader" *ngIf="isAcknowledging" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
                <span>{{ 'pages.default.responses_detail.acknowledge' | translate }}</span>
              </button>
            </div>

            <accordion class="watches">
              @for (watch of pathwayMonitor.watches; track watch.id) {
                <accordion-group
                  [ngClass]="{'new-watch': watch.isNew}"
                  [isDisabled]="isDisabled(watch)"
                  [isOpen]="!isDisabled(watch)">

                  <button accordion-heading type="button" class="btn accordion-heading d-flex align-items-center w-100">
                    <div class="watch">
                      @switch (true) {
                        @case (watch.isNew) {
                          <span [inlineSVG]="'./assets/svg/watch-new.svg'"
                                class="rtl-mirrored-inline-svg watch-new m-end-1"
                                [popover]="'pages.default.responses.monitor.new_watch' | translate"
                                triggers="mouseenter:mouseleave"></span>
                        }
                        @case (watch.isAcknowledged) {
                          <span [inlineSVG]="'./assets/svg/watch-acknowledged.svg'"
                                class="rtl-mirrored-inline-svg watch-acknowledged m-end-1"
                                [popover]="'pages.default.responses.monitor.acknowledged_watch' | translate"
                                triggers="mouseenter:mouseleave"></span>
                        }
                        @case (watch.isActioned) {
                          <span [inlineSVG]="'./assets/svg/watch-actioned.svg'"
                                class="rtl-mirrored-inline-svg watch-actioned m-end-1"
                                [popover]="'pages.default.responses.monitor.actioned_watch' | translate"
                                triggers="mouseenter:mouseleave"></span>
                        }
                      }
                    </div>

                    <p class="mb-0 m-end-1" [ngClass]="{'fw-bold': !watch.isActioned}">
                      {{ watch.titleTranslationKey | translate }}
                    </p>

                    <p class="m-start-auto mb-0 small fw-medium text-muted">
                      @if (watch.isAddedToday) {
                        {{ 'pages.default.responses_detail.watches.today' | translate }}
                      } @else {
                        {{ watch.added_at | timeZoneDate:dateFormat.format }}
                      } {{ 'pages.default.responses_detail.watches.at' | translate }} {{ watch.added_at | time }}
                    </p>

                    @if (!isDisabled(watch)) {
                      <div class="chevron-down">
                        <span [inlineSVG]="'./assets/svg/chevron-down-16.svg'" class="icon"></span>
                      </div>
                    }
                  </button>

                  @if (watch.isActioned) {
                    <div class="d-flex m-end-3">
                      <span [inlineSVG]="'./assets/svg/sub-line.svg'" class="icon"></span>

                      <p class="status-badge info m-end-2" style="margin-top: .8rem">
                        {{ 'pages.default.responses_detail.watches.action' | translate }}
                      </p>

                      <div class="action" style="margin-top: 1.05rem">
                        <p class="fw-bold mb-1">
                          {{ 'pathway_monitor.watch.action.' + watch.action?.toLowerCase() | translate }}
                        </p>

                        <p class="mb-1">{{ watch.action_description }}</p>

                        <p class="text-muted small fw-medium mb-0">
                          {{ 'pages.default.responses_detail.watches.actioned_by' | translate }} {{ watch.actioned_by.getFullName() }}
                        </p>
                      </div>

                      <p class="mb-0 text-muted small fw-medium m-start-auto mb-0 flex-shrink-0"
                         style="margin-top: 1.05rem">
                        @if (watch.isActionedToday) {
                          {{ 'pages.default.responses_detail.watches.today' | translate }}
                        } @else {
                          {{ watch.actioned_at | timeZoneDate:dateFormat.format }}
                        } {{ 'pages.default.responses_detail.watches.at' | translate }} {{ watch.actioned_at | time }}
                      </p>
                    </div>
                  }

                  @if (watch.isAcknowledged) {
                    <div class="d-flex align-items-center m-end-4 mt-1">
                      <p class="mb-0 text-muted small fw-medium">
                        {{ 'pages.default.responses_detail.watches.acknowledged_by' | translate }} {{ watch.acknowledged_by.getFullName() }}
                      </p>

                      <p class="mb-0 text-muted small fw-medium m-start-auto">
                        @if (watch.isAcknowledgedToday) {
                          {{ 'pages.default.responses_detail.watches.today' | translate }}
                        } @else {
                          {{ watch.acknowledged_at | timeZoneDate:dateFormat.format }}
                        } {{ 'pages.default.responses_detail.watches.at' | translate }} {{ watch.acknowledged_at | time }}
                      </p>
                    </div>
                  }
                </accordion-group>

                @if (!$last) {
                  <div class="mb-1"></div>
                }
              }
            </accordion>

            @if (!pathwayMonitor.watches.length) {
              <p class="text-muted">
                {{ 'pages.default.responses_detail.watches.no_watches_available' | translate }}
              </p>
            }
          </div>
        </div>
      </div>
    }
  </div>
</div>
