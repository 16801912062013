<div class="layout-header-pusher header-patient header-shrunk" [ngClass]="{'header-shrunk': !showFullHeader}"></div>

<div class="layout-header header-patient header-shrunk" [ngClass]="{'header-shrunk': !showFullHeader}">
  <div class="header-content">
    <div class="container mb-1">
      <app-breadcrumb *ngIf="!backButtonData && patient"></app-breadcrumb>

      <app-back-link route="{{backButtonData?.url}}" label="{{backButtonData?.titleKey}}" id="patient-detail-action-back-to-specific-url"  *ngIf="backButtonData" />
    </div>

    <div class="container">

      <div class="row">
        <div class="col">
          <div class="patient-info-block">
            <app-avatar [profilePicture]="patient?.profile_picture"></app-avatar>

            <div class="info-wrapper">
              <div class="mb-2">
                <h1 id="patient-detail-name" class="text-truncate mb-0 pb-0">{{ patient?.getFullName() || '...' }}</h1>
                <span class="title-subline mb-1" *ngIf="patient?.preferred_name" id="patient-detail-preferred-name">({{ 'pages.default.patient_detail.prefers' | translate }} {{ patient?.preferred_name }})</span>
              </div>

              <div class="row gx-5">
                <div class="col-4" id="patient-detail-birthday">
                  <p>
                    <span [inlineSVG]="'/assets/svg/cake-outline-24.svg'"></span>
                    <span class="text-truncate">{{ patient?.date_of_birth | timeZoneDate:dateFormat?.format  }} <span class="text-muted">({{ patient?.age() | translateNumber }} {{ 'pages.default.dashboard.yo' | translate }})</span></span>
                  </p>
                </div>

                <div class="col-4" id="patient-detail-id">
                  <p>
                    <span [inlineSVG]="'/assets/svg/id-24.svg'"></span>
                    <span class="text-truncate">{{ (patient?.electronic_medical_record | translateNumber) || ('pages.default.patient_detail.not_available' | translate) }}</span>
                  </p>
                </div>


                <div class="col-4" *ngIf="showFullHeader" id="patient-detail-language">
                  <p>
                    <span [inlineSVG]="'/assets/svg/language-24.svg'"></span>
                    <span class="text-truncate">{{ getLanguage()?.translationKey | translate }}</span>
                  </p>
                </div>

                <div class="col-4" *ngIf="showFullHeader" id="patient-detail-phone">
                  <p>
                    <span [inlineSVG]="'/assets/svg/mobile-device-outline-24.svg'"></span>
                    <span class="text-truncate" *ngIf="patient?.phone_number" dir="ltr">{{ generalService.getDialCodeForCountry(patient?.phone_number?.code)?.label}} {{ patient?.phone_number?.number }}</span>
                    <span class="text-truncate" *ngIf="!patient?.phone_number">{{ 'pages.default.patient_detail.not_available' | translate }}</span>
                  </p>
                </div>

                <div class="col-4" *ngIf="showFullHeader && patient?.email" id="patient-detail-mail">
                    <p>
                      <span [inlineSVG]="'/assets/svg/envelope-outline-24.svg'"></span>
                      <a href="mailto:{{ patient?.email }}" id="patient-detail-mailto" target="_blank" rel=noopener class="text-truncate">
                        {{ patient?.email }}
                      </a>
                    </p>
                </div>

                <div class="col-4" *ngIf="showFullHeader" id="patient-detail-gender">
                  <p>
                    <span [inlineSVG]="'/assets/svg/gender-24.svg'"></span>
                    <span class="text-truncate" *ngIf="patient?.gender==='MALE'">{{ 'form.labels.male' | translate }}</span>
                    <span class="text-truncate" *ngIf="patient?.gender==='FEMALE'">{{ 'form.labels.female' | translate }}</span>
                    <span class="text-truncate" *ngIf="patient?.gender==='UNKNOWN'">{{ 'form.labels.other' | translate }}</span>
                  </p>
                </div>

                <div [ngClass]="showFullHeader ? 'col-12' : 'col-4'">

                    <button (click)="actionToggleHeader($event)" class="btn btn-as-text" id="action_patientHeaderToggle" [ngClass]="showFullHeader ? 'mt-1' : 'mt-0'">
                      <span *ngIf="!showFullHeader" class="align-middle" id="label_header_show_more_details" >{{ 'pages.default.patient_detail.header_show_more_details' | translate }}</span>
                      <span *ngIf="!showFullHeader" [inlineSVG]="'/assets/svg/chevron-down-24.svg'"></span>

                      <span *ngIf="showFullHeader" class="align-middle" id="label_header_show_less_details">{{ 'pages.default.patient_detail.header_show_less_details' | translate }}</span>
                      <span *ngIf="showFullHeader" [inlineSVG]="'/assets/svg/chevron-up-24.svg'"></span>
                    </button>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-auto">

          <button id="button_patient_detail_edit_details" class="btn btn-secondary btn-sm" (click)="editPatient()"  *ngIf="hasCcRole">{{'pages.default.patient_detail.edit_details' | translate}}</button>

          <div class="dropdown d-inline-block m-start-1" dropdown placement="bottom right" *ngIf="hasCcRole && pathwaysAvailable && scopeService.doesCurrentPatientHasClicinalCareModule()">
            <button id="button_patient_detail_settings" dropdownToggle type="button" class="dropdown-toggle btn btn-tertiary btn-icon btn-sm" aria-controls="dropdown_patient_detail_settings_dropdown">
              <span [inlineSVG]="'/assets/svg/more-24.svg'"></span>
            </button>
            <ul id="dropdown_patient_detail_settings_dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button_patient_detail_settings">
              <li role="menuitem">
                <a id="patient-detial-edit-mdt" class="dropdown-item" href="" (click)="editMdt($event)">{{'pages.default.patient_detail.edit_mdt' | translate }}</a>
              </li>

              <li role="menuitem">
                <a id="patient-detail-add-pathway" class="dropdown-item" href="" (click)="addPathway($event)">{{'pages.default.patient_detail.add_pathway' | translate }}</a>
              </li>

              <li role="menuitem">
                <a id="patient-detail-stop-pathway" class="dropdown-item" href="" (click)="stopPathway($event)">{{'pages.default.patient_detail.stop_pathway' | translate }}</a>
              </li>

            </ul>
          </div>

        </div>
      </div>
    </div>

    <div class="action-tabs-bar">
      <div class="container" *ngIf="selectedPathway">
        <div class="bar-actions">

          <!-- When there is only 1 patient pathway, show read-only -->
          <div id="button-patient-pathways"
            class="action btn btn-tertiary read-only"
            *ngIf="getPathwayListWithoutSelected(selectedPathway)?.length<1"
            >
            <span *ngIf="selectedPathway?.care_module">{{ selectedPathway?.care_module?.name | translateHelp | translate }}</span>
            <span *ngIf="!selectedPathway?.care_module">...</span>
          </div>

          <!-- when there are more than 1 patient pathways, show the dropdown button -->
          <div class="dropdown" dropdown placement="top right" *ngIf="getPathwayListWithoutSelected(selectedPathway)?.length>0">
            <button id="button-patient-pathways"
                    dropdownToggle
                    type="button"
                    class="action btn btn-tertiary"
                    aria-controls="dropdown-patient-pathways"
                    >
              <span *ngIf="selectedPathway?.care_module">{{ selectedPathway?.care_module?.name | translateHelp | translate }}</span>
              <span *ngIf="!selectedPathway?.care_module">...</span>
              <span [inlineSVG]="'/assets/svg/chevron-down-16.svg'" class="icon"></span>
            </button>
            <ul id="dropdown-patient-pathways" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-patient-pathways">
              <li role="menuitem" *ngFor="let pathway of getPathwayListWithoutSelected(selectedPathway)">
                <a (click)="onSelectPathway($event, pathway)"
                  class="dropdown-item"
                  [id]="['button-patient-pathway-' + pathway?.uid]"
                  href="">
                  {{ pathway?.care_module?.name | translateHelp | translate }}
                </a>
              </li>
            </ul>
          </div>

        </div>
        <div class="bar-tabs">
          <app-page-tabs [items]="pageTabItems"></app-page-tabs>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="body-content" style="min-height: 120vh;">
  <div class="container" *ngIf="pathwayLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!pathwayLoading && !pathwaysAvailable">
    <div class="empty-state my-5 my-lg-7 my-xl-9">
      <span [inlineSVG]="'/assets/svg-color/no-notifications-graphic.svg'"></span>
      <h6 class="h7">{{ 'pages.default.patient_detail.no_active_pathways' | translate }}</h6>
    </div>
  </div>

  <div class="container" *ngIf="!pathwayLoading && !pathwayAvailable">
    <div class="empty-state my-5 my-lg-7 my-xl-9">
      <span [inlineSVG]="'/assets/svg-color/no-notifications-graphic.svg'"></span>
      <h6 class="h7">{{ 'pages.default.patient_detail.pathway_not_available' | translate }}</h6>
    </div>
  </div>

  <div class="container"  *ngIf="!pathwayLoading && selectedPathway && pathwayAvailable">

    <!--  -->
    <!-- Reminders - Start -->
    <!--  -->
    <app-patient-detail-reminders [pathway]="selectedPathway" [patient]="patient" [tasks]="tasks" (changed)="refreshPathway()" *ngIf="scopeService.doesCurrentPatientHasClicinalCareModule()"></app-patient-detail-reminders>
    <!--  -->
    <!-- Reminders - End -->
    <!--  -->


    <!--  -->
    <!-- Timeline - Start -->
    <!--  -->
    <div id="zone_timeline" class="mb-5" *ngIf="scopeService.doesCurrentPatientHasClicinalCareModule()">
      <div class="heading-block">
        <h5>{{ 'pages.default.patient_detail.zone_timeline' | translate }}</h5>

        <a id="patient-timeline" href="" class="btn btn-secondary block-action" [routerLink]="['/patient/' + patientUid + '/' + selectedPathway?.uid + '/' + '/timeline']">
          {{ 'pages.default.patient_detail.details' | translate }}
        </a>
      </div>

      <hr class="my-5">
    </div>
    <!--  -->
    <!-- Timeline - End -->
    <!--  -->


    <!--  -->
    <!-- Surgery dates - Start -->
    <!--  -->
    <app-patient-detail-surgery-dates [pathway]="selectedPathway" [patient]="patient" [tasks]="surgeryTasks" [appointments]="surgeryAppointments" (changed)="updateSurgeryDates()"></app-patient-detail-surgery-dates>
    <!--  -->
    <!-- Surgery dates - End -->
    <!--  -->


    <!--  -->
    <!-- Materials - Start -->
    <!--  -->
    <div id="zone_materials">
      <div class="heading-block">
        <h5>{{ 'pages.default.patient_detail.zone_materials' | translate }}</h5>

        <a href="" id="show-more-learning-materials" class="btn btn-secondary block-action" [routerLink]="['/patient/' + patientUid + '/' + selectedPathway?.uid + '/' + '/learning-materials']">
          {{ 'pages.default.patient_detail.details' | translate }}
        </a>
      </div>

      <app-material-phases-table [pathway]="selectedPathway"></app-material-phases-table>

      <hr class="my-5">
    </div>
    <!--  -->
    <!-- Materials - End -->
    <!--  -->


    <!--  -->
    <!-- Checklists - Start -->
    <!--  -->
    <div id="zone_checklists">
      <app-patient-detail-querylists [patientUid]="patientUid" [pathwayUid]="selectedPathway?.uid" [type]="'CHECKLIST'"></app-patient-detail-querylists>
    </div>
    <!--  -->
    <!-- Checklists - End -->
    <!--  -->

    <hr class="my-5">

    <!--  -->
    <!-- Forms - Start -->
    <!--  -->
    <div id="zone_essential_forms">
      <app-patient-detail-querylists [patientUid]="patientUid" [pathwayUid]="selectedPathway?.uid" [type]="'QUESTIONNAIRE'" *ngIf="scopeService.doesCurrentPatientHasClicinalCareModule()"></app-patient-detail-querylists>
    </div>
    <!--  -->
    <!-- Forms - End -->
    <!--  -->

    <hr class="my-5">

    <!--  -->
    <!-- Goals - Start -->
    <!--  -->
    <div id="zone_goals" class="mb-5" *ngIf="selectedPathway?.goals && scopeService.doesCurrentPatientHasClicinalCareModule()">
      <div class="heading-block">
        <h5>{{ 'pages.default.patient_detail.zone_goals' | translate }}</h5>
      </div>

      <div class="bg-white px-2 py-4 rounded-3" *ngIf="!selectedPathway?.goals?.length">
        <p class="m-0 text-center text-muted">{{ 'pages.default.patient_detail.patient_no_goals' | translate }}</p>
      </div>

      <div class="row" *ngIf="selectedPathway?.goals?.length">
        <div class="col-4 mb-2" id=goal-instance-{{index}} *ngFor="let goalInstance of selectedPathway?.goals; index as index">
          <div class="card card-goal h-100">
            <div class="card-header">
              <a href="" [routerLink]="['/patient/' + patientUid + '/' + selectedPathway?.uid + '/' + '/goal/' + goalInstance?.id]">{{ goalInstance?.title | translateHelp | translate }}</a>
            </div>

            <div class="card-body card-body">
              <div class="goal-box goal-box-graph" *ngIf="goalInstance?.evaluation?.evaluation_graph === 'PROGRESS' && goalInstance.evaluation?.available()">
                <circle-progress
                  [percent]="goalService.getProgressPercentage(goalInstance)"
                  [title]="goalService.getProgressTitle(goalInstance)"
                  [subtitle]="goalService.getProgressSubtitle(goalInstance) | translate"
                ></circle-progress>
              </div>

              <div class="goal-box goal-box-graph" *ngIf="goalInstance?.evaluation?.evaluation_graph === 'PROGRESS' && !goalInstance.evaluation?.available()">
                <label class="text-center py-3"> {{ 'pages.default.goal_detail.no_recent_data_available' | translate }}</label>
              </div>

              <div class="goal-box goal-box-text" *ngIf="goalInstance?.evaluation?.evaluation_graph === 'TEXT'">
                <p class="title text-truncate mb-1">{{ goalInstance?.evaluation?.evaluation_value || '-' }}</p>
                <p *ngIf="goalInstance?.evaluation?.evaluation_unit">{{ goalInstance?.evaluation?.evaluation_unit | translateHelp | translate }}</p>
                <label class="text-center mt-2" *ngIf="!goalInstance.evaluation?.available()"> {{ 'pages.default.goal_detail.no_recent_data_available' | translate }}</label>
              </div>

            </div>
            <div class="card-footer">
              <a href="" class="btn btn-tertiary btn-icon m-start-auto" [routerLink]="['/patient/' + patientUid + '/' + selectedPathway?.uid + '/' + '/goal/' + goalInstance?.id]">
                <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/arrow-right-24.svg'"></span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <hr class="my-5">
    </div>
    <!--  -->
    <!-- Goals - End -->
    <!--  -->


    <!--  -->
    <!-- Notes - Start -->
    <!--  -->
    <div id="zone_notes" class="mb-5" *ngIf="scopeService.doesCurrentPatientHasClicinalCareModule()">
      <div class="heading-block">
        <h5>{{ 'pages.default.patient_detail.zone_notes' | translate }}</h5>

        <div class="m-start-auto"></div>

        <a href="" class="btn btn-tertiary block-action" id="add-new-note" [routerLink]="['/patient/' + patientUid + '/' + selectedPathway?.uid + '/notes/new']" [queryParams]="{'back-to': 'patient-detail'}">
          <span class="icon" [inlineSVG]="'/assets/svg/add-24.svg'"></span>
          <span>{{ 'pages.default.notes.add_note' | translate }}</span>
        </a>

        <a href="" id="show-more-notes" class="btn btn-secondary m-start-2 block-action" [routerLink]="['/patient/' + patientUid + '/' + selectedPathway?.uid + '/notes']">
          {{ 'pages.default.patient_detail.details' | translate }}
        </a>
      </div>

      <div class="bg-white px-2 py-2 rounded-3" *ngIf="notesLoading">
        <app-page-loader></app-page-loader>
      </div>

      <div *ngIf="!notesLoading">
        <div class="bg-white px-2 py-4 rounded-3" *ngIf="notes?.length === 0">
          <p class="m-0 text-center text-muted">{{ 'pages.default.patient_detail.patient_no_notes' | translate }}</p>
        </div>

        <div class="row" *ngIf="notes?.length > 0">
          <div class="col-6 mb-2" *ngFor="let note of notes">
            <div class="card h-100">

              <div class="card-header">
                <p class="caption">{{ note?.changed_at | timeZoneDate}}</p>
                <a href="" [routerLink]="['/patient/' + patientUid + '/' + selectedPathway?.uid + '/notes/' + note?.uid + '/view']" [queryParams]="{'back-to': 'patient-detail'}">{{ note?.title }}</a>
              </div>

              <div class="card-body">
                <div class="user-compact mb-3">
                  <app-avatar [profilePicture]="note?.created_by?.profile_picture"></app-avatar>

                  <div class="user-compact-info">
                    <p class="title text-truncate">{{ note?.created_by?.getFullName() }}</p>
                    <p>{{ note?.created_by?.job_title }}</p>
                  </div>
                </div>

                <p class="m-0">{{ note?.note_preview }}</p>
              </div>

              <div class="card-footer">
                <div class="m-end-auto"></div>

                @if(note?.created_by?.uid === currentHcpUid) {
                  <div class="dropdown m-end-2" dropdown placement="top right">

                    <button id="button_note_dropdown" dropdownToggle type="button" class="dropdown-toggle btn btn-tertiary btn-icon btn-sm" aria-controls="note_dropdown">
                      <span [inlineSVG]="'/assets/svg/more-24.svg'"></span>
                    </button>

                    <ul id="note_dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button_note_dropdown">
                      <li role="menuitem">
                        <a class="dropdown-item" href="" [routerLink]="['/patient/' + patientUid + '/' + selectedPathway?.uid + '/notes/' + note?.uid + '/edit']" [queryParams]="{'back-to': 'patient-detail'}">{{ 'pages.default.patient_detail.edit_note' | translate }}</a>
                        <a class="dropdown-item text-danger" href="" (click)="deleteNote($event, note?.uid)">{{ 'pages.default.patient_detail.delete_note' | translate }}</a>
                      </li>
                    </ul>
                  </div>
                }

                <a href="" class="btn btn-tertiary btn-icon" [routerLink]="['/patient/' + patientUid + '/' + selectedPathway?.uid + '/notes/' + note?.uid + '/view']" [queryParams]="{'back-to': 'patient-detail'}">
                  <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/arrow-right-24.svg'"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p class="text-end" *ngIf="totalNotes > 0">
        <a href="" id="link_notes_show_more" class="btn btn-link" [routerLink]="['/patient/' + patientUid + '/' + selectedPathway?.uid + '/' + 'notes']">
          <span class="label">{{ 'pages.default.patient_detail.show_all' | translate }} ({{ totalNotes }}) </span>
        </a>
      </p>

      <hr class="my-5">
    </div>
    <!--  -->
    <!-- Notes - End -->
    <!--  -->


    <!--  -->
    <!-- MDT - Start -->
    <!--  -->
    <div id="zone_mdt" class="mb-5" *ngIf="scopeService.doesCurrentPatientHasClicinalCareModule()">
      <div class="heading-block">
        <h5>{{ 'pages.default.patient_detail.zone_mdt' | translate }}</h5>

        <a href="" class="btn btn-secondary block-action" id="edit-mdt" (click)="editMdt($event)">
          <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/edit-outline-24.svg'"></span>
          <span>{{'pages.default.patient_detail.edit_mdt' | translate }}</span>
        </a>
      </div>

      <div class="bg-white px-2 py-4 rounded-3" *ngIf="!selectedPathway?.clinical_lead && !selectedPathway?.case_manager && !selectedPathway?.patient_mdt?.mdts?.length"></div>

      <div class="row mb-5" *ngIf="selectedPathway?.clinical_lead || selectedPathway?.case_manager">
        <div id="clinical-lead" class="col-6" *ngIf="selectedPathway?.clinical_lead">

          <p class="lead font-weight-semibold">{{ 'pages.default.patient_detail.clinical_lead' | translate }}</p>

          <div class="participant-item item-lg bg-white">
            <app-avatar [profilePicture]="selectedPathway?.clinical_lead?.profile_picture"></app-avatar>

            <div class="meta">
              <p class="title">{{selectedPathway?.clinical_lead?.getFullName()}}</p>
              <p class="description">{{ selectedPathway?.clinical_lead?.job_title }}</p>
            </div>

            <div class="status-badge badge-sm clinical-lead">
              {{ 'pages.default.patient_detail.clinical_lead' | translate }}
            </div>
          </div>
        </div>

        <div id="case-manager" class="col-6" *ngIf="selectedPathway?.case_manager">

          <p class="lead font-weight-semibold">{{ 'pages.default.patient_detail.case_manager' | translate }}</p>

          <div class="participant-item item-lg bg-white">
            <app-avatar [profilePicture]="selectedPathway?.case_manager?.profile_picture"></app-avatar>

            <div class="meta">
              <p class="title">{{selectedPathway?.case_manager?.getFullName()}}</p>
              <p class="description">{{ selectedPathway?.case_manager?.job_title }}</p>
            </div>

            <div class="status-badge badge-sm case-manager">
              {{ 'pages.default.patient_detail.case_manager' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div id="mdt-{{index}}" class="mb-5" *ngFor="let mdt of selectedPathway?.patient_mdt.mdts; index as index">
        <p class="lead font-weight-semibold">{{mdt.name}}</p>
        <div class="row g-2">

          <div class="col-6" *ngFor="let hcp of mdt.hcps">
            <div class="participant-item item-lg bg-white">
              <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>

              <div class="meta">
                <p class="title">{{hcp.getFullName()}}</p>
                <p class="description">{{ hcp?.job_title }}</p>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div id="mdt-other" class="mb-5" *ngIf="selectedPathway?.patient_mdt?.otherHcps?.length">
        <p class="lead font-weight-semibold">{{ 'pages.default.patient_detail.mdt.other' | translate }}</p>
        <div class="row g-2">
          <div class="col-6" *ngFor="let hcp of selectedPathway?.patient_mdt.otherHcps">
            <div class="participant-item bg-white">
              <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>

              <div class="meta">
                <p class="title">{{hcp.getFullName()}}</p>
                <p class="description">{{ hcp?.job_title }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <!-- MDT - End -->
    <!--  -->

  </div>
</div>
