<div class="layout-header-pusher header-title-only"></div>

<div class="layout-header header-title-only">
  <div class="header-content">
    <div class="container">
      <h5>{{ 'pages.default.settings.overview.settings' | translate }}</h5>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">

    <div class="single-item-block block-compact item-clickable mb-5 mb-lg-6 mb-xl-8"
          id="edit-personal-information"
          routerLink="['/settings/personal-information']">
      <div class="block-wrapper p-start-2 p-end-0">
        <span class="avatar" [style.backgroundImage]="'url(' + (hcp?.profile_picture?.url | secure | async) + ')'" [ngClass]="{'empty': !getProfilePicture()}"></span>
      </div>
      <div class="block-wrapper">
        <p class="label label-lg">{{getFullName()}}</p>
        <p class="value">{{ 'pages.default.settings.overview.personal_info_contact_details' | translate }}</p>
      </div>
      <div class="block-btn-wrapper">
        <div class="block-action btn btn-lg btn-tertiary btn-icon">
          <span [inlineSVG]="'./assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg" id="edit-personal-information-arrow"></span>
        </div>
      </div>
    </div>

    <!-- Temp hidden with 'd-none' -->
    <div class="d-none single-item-block item-clickable mb-2" [ngClass]="{'block-info text-info': ooo?.currentlyEnabled()}"
        id="edit-ooo"
        routerLink="['/settings/ooo']">
      <div class="block-icon">
        <span class="icon" [inlineSVG]="'./assets/svg/building-outline-24.svg'"></span>
      </div>
      <div class="block-wrapper m-end-auto">
        <p class="label" [ngClass]="{'text-info': ooo?.currentlyEnabled()}">{{ 'pages.default.settings.overview.ooo' | translate }}</p>
        <p class="value" [ngClass]="{'text-info': ooo?.currentlyEnabled()}">{{ 'pages.default.settings.overview.ooo_info' | translate }}</p>
      </div>
      <div class="block-wrapper m-end-2" *ngIf="ooo?.currentlyEnabled()">
        <p class="text-info">Active until {{ ooo?.end_date | timeZoneDate:dateFormat?.format }}</p>
      </div>
      <div class="block-btn-wrapper">
        <div class="block-action btn btn-lg btn-tertiary btn-icon">
          <span [inlineSVG]="'./assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg" id="edit-ooo-arrow"></span>
        </div>
      </div>
    </div>

    <div class="single-item-block item-clickable mb-2"
        id="edit-preferences"
        routerLink="['/settings/preferences']">
      <div class="block-icon">
        <span class="icon" [inlineSVG]="'./assets/svg/filter-outline-24.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.overview.preferences' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.overview.preferences_info' | translate }}</p>
      </div>
      <div class="block-btn-wrapper">
        <div class="block-action btn btn-lg btn-tertiary btn-icon">
          <span [inlineSVG]="'./assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg" id="edit-preferences-arrow"></span>
        </div>
      </div>
    </div>

    <div class="single-item-block item-clickable mb-2"
        id="edit-security"
        routerLink="['/settings/security']">
      <div class="block-icon">
        <span class="icon" [inlineSVG]="'./assets/svg/locked-outline-16.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.overview.security' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.overview.security_info' | translate }}</p>
      </div>
      <div class="block-btn-wrapper">
        <div class="block-action btn btn-lg btn-tertiary btn-icon">
          <span [inlineSVG]="'./assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg" id="edit-security-arrow"></span>
        </div>
      </div>
    </div>

    <div class="single-item-block item-clickable mb-2"
        id="edit-privacy-legal"
        routerLink="['/settings/privacy']">
      <div class="block-icon">
        <span class="icon" [inlineSVG]="'./assets/svg/privacy-outline-24.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.overview.privacy' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.overview.privacy_info' | translate }}</p>
      </div>
      <div class="block-btn-wrapper">
        <div class="block-action btn btn-lg btn-tertiary btn-icon">
          <span [inlineSVG]="'./assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg" id="edit-privacy-legal-arrow"></span>
        </div>
      </div>
    </div>


    <!-- d-none: temp hidden -->
    <div class="d-none single-item-block item-clickable mb-2"
        id="edit-personal-data"
        routerLink="['/settings/personal-data']">
      <div class="block-icon">
        <!-- <span class="icon" [inlineSVG]="'./assets/svg/xxxxxx.svg'"></span> -->
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.overview.personal_data' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.overview.manage_your_account' | translate }}</p>
      </div>
      <div class="block-btn-wrapper">
        <div class="block-action btn btn-lg btn-tertiary btn-icon">
          <span [inlineSVG]="'./assets/svg/arrow-right-32.svg'" class="rtl-mirrored-inline-svg" id="edit-personal-data-arrow"></span>
        </div>
      </div>
    </div>

  </div>
</div>
