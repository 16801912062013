<div class="card material-phases-table-card" *ngIf="flatMaterialPhases?.length">
  <div class="card-body">

    <div
      *ngFor="let phaseProgress of flatMaterialPhases"
      class="item"
      [ngClass]="{
        'sub-item': phaseProgress?.isSubPhase
      }"
    >
      <p class="title">{{phaseProgress?.name | translateHelp | translate}}</p>

      <div class="progress">
        <div
          class="progress-bar"
          role="progressbar"
          [style.width]="phaseProgress?.percentage"
          [ngClass]="{
            'bg-success': phaseProgress?.isFinished
          }"
        ></div>
      </div>

      <p class="stats">{{phaseProgress?.completed}}/{{phaseProgress?.total}} {{ 'pages.default.patient_materials.completed' | translate }}</p>
    </div>
  </div>
</div>

<div class="bg-white px-2 py-4 rounded-3" *ngIf="!flatMaterialPhases?.length">
  <p class="m-0 text-center text-muted">{{ 'pages.default.patient_detail.patient_no_materials' | translate }}</p>
</div>
