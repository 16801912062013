import { Component, Input } from '@angular/core';
import { Pathway } from '../../models/pathway';
import { PathwayService } from '../../services/pathway.service';
import { MaterialPhase } from '../../models/material-phase';

@Component({
  selector: 'app-material-phases-table',
  standalone: false,
  templateUrl: './material-phases-table.component.html',
  styleUrl: './material-phases-table.component.scss'
})
export class MaterialPhasesTableComponent  {
  @Input()
  set pathway(pathway: Pathway) {
    this._pathway = pathway;
    this.mapToFlatList(pathway.educational_materials);
  }
  get pathway(): Pathway {
    return this._pathway;
  }
  private _pathway: Pathway;

  public flatMaterialPhases: MaterialPhase[];

  constructor(
    public pathwayService: PathwayService,
  ) {}


  mapToFlatList(materialPhases: MaterialPhase[]) {
    this.flatMaterialPhases = [];

    materialPhases.forEach(materialPhase => {
      this.flatMaterialPhases.push(materialPhase);

      if (materialPhase.sub_phases) {
        materialPhase.sub_phases.forEach(subPhase => this.flatMaterialPhases.push(subPhase));
      }
    });
  }
}
