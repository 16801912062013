import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { environment } from '../../environments/environment';
import {MedicalTeam} from '../models/medical-team';
import {ScopeService} from '../services/scope.service';
import {ApiService} from '../services/api.service';
import { PagingDto } from '../interfaces/paging-dto';
import { HttpClient } from '@angular/common/http';
import {AuthenticationService} from '../services/authentication.service';
import {HealthCareProfessional} from '../models/health-care-professional';
import { HcpService } from './hcp.service';

@Injectable({
  providedIn: 'root'
})
export class MdtService extends ApiService {

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public scopeService: ScopeService,
    public hcpService: HcpService
  ) {
    super(http, authenticationService);
  }

  private static _postableMdt(mdt: MedicalTeam): object {
    return {
      name: mdt?.name,
      hcps: mdt?.hcps.map((hcp: HealthCareProfessional) => { return {"uid": hcp.uid} } )
    };
  }
  private static _puttableMdt(mdt: MedicalTeam): object {
    return {
      name: mdt?.name,
    };
  }
  private static _postableHcp(hcpUid: string): object {
    return {
      hcp_uid: hcpUid
    };
  }

  public getPaged(
    filters: any = {},
    sort = 'name,asc',
    page = 0,
    size = 30
  ): Observable<PagingDto<MedicalTeam>> {
    return new Observable(observer => {
      const paramBits = [
        `page=${String(page)}`,
        `size=${String(size)}`
      ];

      if (sort) {
        paramBits.push(`sort=${sort}`);
      }

      if (filters?.name) {
        paramBits.push(`name=${(filters.name).toString()}`);
      }

      if (filters?.hcpLastName) {
        paramBits.push(`hcps.last_name=${filters.hcpLastName}`);
      }

      if (filters?.functionKeys && filters.functionKeys.length) {
        filters.functionKeys.forEach(functionKey => {
          paramBits.push(`hcps.function=${functionKey}`);
        });
      }

      const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
      const paramsString = paramBits.join('&');
      const url = `${environment.platformUrl}/hospitals/${hospitalUid}/mdts?${paramsString}`;
      this.authenticatedGet(url).subscribe(result => {
        const mdts: Array<MedicalTeam> = new Array();

        for (const item of result['items']) {
          const mdt = new MedicalTeam(item);

          mdts.push(mdt);
        }

        observer.next({'items': mdts, 'pagination': result['pagination']});
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public create(mdt: MedicalTeam): Observable<MedicalTeam> {
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    const url = `${environment.platformUrl}/hospitals/${hospitalUid}/mdts`;

    return new Observable<MedicalTeam>(observer => {
      this.authenticatedPost(url, MdtService._postableMdt(mdt))
        .subscribe(result => {
          observer.next(new MedicalTeam(result));
          observer.complete();
      }, error => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  public get(mdtUid: string): Observable<MedicalTeam> {
    return new Observable<MedicalTeam>(observer => {
      const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
      const url = `${environment.platformUrl}/hospitals/${hospitalUid}/mdts/${mdtUid}`;

      this.authenticatedGet(url).subscribe(result => {
        observer.next(new MedicalTeam(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  // does not update the hcps of the mdt!
  // use addHcp() and deleteHcp() functions for that
  // Only updates mdt name
  public update(mdt: MedicalTeam): Observable<MedicalTeam> {
    return new Observable<MedicalTeam>(observer => {
      const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
      const url = `${environment.platformUrl}/hospitals/${hospitalUid}/mdts/${mdt.uid}`;

      this.authenticatedPut(url, MdtService._puttableMdt(mdt)).subscribe(result => {
        observer.next(new MedicalTeam(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public addHcp(mdt: MedicalTeam, hcpUid: string): Observable<void> {
    return new Observable<void>(observer => {
      const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
      const url = `${environment.platformUrl}/hospitals/${hospitalUid}/mdts/${mdt.uid}/hcps`;

      this.authenticatedPost(url, MdtService._postableHcp(hcpUid))
        .subscribe(() => {
          observer.next();
          observer.complete();
        }, error => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  public deleteHcp(mdt: MedicalTeam, hcpUid: string): Observable<void> {
    return new Observable<void>(observer => {
      const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
      const url = `${environment.platformUrl}/hospitals/${hospitalUid}/mdts/${mdt.uid}/hcps/${hcpUid}`;

      this.authenticatedDelete(url)
        .subscribe(() => {
          observer.next();
          observer.complete();
        }, error => {
          observer.error(error);
          observer.complete();
        });
    });
  }
}
