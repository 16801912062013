<div class="default-layout" [ngClass]="{'open': sidebarOpen}">
  <button
    class="default-layout-backdrop p-0"
    [ngClass]="{'visible': backdropVisible, 'shown': backdropShown}"
    (click)="closeSidebarHandler($event);"
  ></button>
  <div class="layout-sidebar">
    <div class="sidebar-content is-flexed">
      <div class="content-part pb-0">
        <div class="sidebar-toggle">
          <a href="" class="toggle-open" (click)="closeSidebarHandler($event);">
            <span class="rtl-d-none" [inlineSVG]="'./assets/svg/chevron-left-24.svg'"></span>
            <span class="d-none rtl-d-inline" [inlineSVG]="'./assets/svg/chevron-right-24.svg'"></span>
          </a>

          <div class="logo text-jnj">
            <div class="logo-compact" [inlineSVG]="'./assets/svg-color/polyphonic-hexagon.svg'"></div>
            <div class="logo-full" [inlineSVG]="'./assets/svg/logo-polyphonic.svg'"></div>
          </div>

          <a href="" class="toggle-closed"
            [inlineSVG]="'./assets/svg/hamburger-24.svg'"
            (click)="openSidebarHandler($event);"
          ></a>

          <!-- btn-notifications - 1 patient, 1 hospital -->
          <a href="" class="btn-notifications new d-none" [inlineSVG]="'./assets/svg/notification-bell-24.svg'"></a>
        </div>
      </div>

      <app-sidebar-banner></app-sidebar-banner>

      <div class="content-part">
        <p class="sidebar-title sidebar-open-element-block">{{ 'layouts.default.nav.title_hospital' | translate }}</p>

        <div class="action-bar">
          <app-avatar [profilePicture]="currentHcp?.profile_picture"></app-avatar>

          <div class="input-wrapper">
            <span class="input m-end-2">
              <span class="main-context input m-end-2" *ngIf="currentHcp">{{ currentHcp?.getFullName() }}</span>
              <span class="sub-context input m-end-2" *ngIf="currentPermissionHospital">{{ currentPermissionHospital?.name }}</span>
            </span>

            <!--
            <span [inlineSVG]="'./assets/svg/chevron-down-24.svg'"></span>
            -->
          </div>

          <!--
          <a href="" class="action-bar-btn rtl-mirrored-inline-svg" id="sidebar-notification-active" [inlineSVG]="'./assets/svg/xxxxxx.svg'"></a>
          -->
        </div>

        <div class="nav-item" [ngClass]="{'active': activeState === 'dashboard'}">
          <a href="" class="nav-link" id="sidebar-dashboard" [routerLink]="['/dashboard']">
            <div class="icon rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/stethoscope-outline-24.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.item_patients' | translate }}</div>
          </a>
        </div>
<!--        // TODO: HIDDEN UNTIL AGIK-6111 -->
<!--        <div class="nav-item" [ngClass]="{'active': activeState === 'responses'}" *ngIf="authenticationService.hasCcRole()">-->
<!--          <a href="" class="nav-link" id="sidebar-responses" [routerLink]="['/responses']">-->
<!--            <div class="icon rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/risk-metric-outline-24.svg'"></div>-->
<!--            <div class="label">{{ 'layouts.default.nav.item_responses' | translate }}</div>-->
<!--          </a>-->

<!--          <a href="" class="nav-badge" [routerLink]="['/responses']" id="sidebar-responses-badge-count"-->
<!--             *ngIf="attentionCenter?.monitors_requiring_attention">-->
<!--            {{ attentionCenter?.monitors_requiring_attention | translateNumber }}-->
<!--          </a>-->
<!--        </div>-->
        <div class="nav-item" [ngClass]="{'active': activeState === 'calendar'}"
          *ngIf="scopeService.doesCurrentHcpHasPermission('APPOINTMENT')">
          <a href="" class="nav-link" id="sidebar-calendar" [routerLink]="['/calendar']">
            <div class="icon" [inlineSVG]="'./assets/svg/calendar-outline-24.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.item_calendar' | translate }}</div>
          </a>
          <a href="" class="nav-badge" [routerLink]="['/calendar']" id="sidebar-calendar-badge-count"
            *ngIf="attentionCenter?.appointmentsPending">{{ attentionCenter?.appointmentsPending | translateNumber
            }}</a>
        </div>
        <div class="nav-item" [ngClass]="{'active': activeState === 'messages'}"
          *ngIf="scopeService.doesCurrentHcpHasPermission('MESSAGING')">
          <a href="" class="nav-link" id="sidebar-conversations" [routerLink]="['/conversations']">
            <div class="icon rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/chat-outline-24.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.item_messages' | translate }}</div>
          </a>
          <a href="" class="nav-badge" [routerLink]="['/conversations']" id="sidebar-conversations-badge-count"
            *ngIf="attentionCenter?.conversationMessagesUnread">{{ attentionCenter?.conversationMessagesUnread |
            translateNumber }}</a>
        </div>
        <div class="nav-item" [ngClass]="{'active': activeState === 'tasks'}" >
          <a href="" class="nav-link" id="sidebar-tasks" [routerLink]="['/tasks']">
            <div class="icon" [inlineSVG]="'./assets/svg/checklist-24.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.item_tasks' | translate }}</div>
          </a>
          <a href="" class="nav-badge" [routerLink]="['/tasks']" *ngIf="attentionCenter?.tasks">{{ attentionCenter?.tasks | translateNumber }}</a>
        </div>
        <div class="nav-item" [ngClass]="{'active': activeState === 'analytics'}"
          *ngIf="scopeService.doesCurrentHcpHasPermission('ANALYTICS')">
          <a href="" class="nav-link" id="sidebar-analytics" [routerLink]="['/analytics']">
            <div class="icon rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/analytics-outline-24.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.item_analytics' | translate }}</div>
          </a>
        </div>
        <div class="nav-item" [ngClass]="{'active': activeState === 'administration'}"
          *ngIf="this.authenticationService.hasCcRole()">
          <a href="" class="nav-link" id="sidebar-administration" [routerLink]="['/administration']">
            <div class="icon rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/building-outline-24.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.item_administration' | translate }}</div>
          </a>
          <a href="" class="nav-badge" [routerLink]="['/administration']" *ngIf="attentionCenter?.selfRegisteredPatientsPending">{{ attentionCenter?.selfRegisteredPatientsPending | translateNumber }}</a>
        </div>
      </div>
      <div class="content-part mt-auto mb-0">
        <p class="sidebar-title sidebar-open-element-block">{{ 'layouts.default.nav.title_account' | translate }}</p>

        <div class="nav-item" [ngClass]="{'active': activeState === 'settings'}">
          <a href="" class="nav-link" id="sidebar-account-settings" [routerLink]="['/settings']">
            <div class="icon rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/settings-outline-24.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.item_settings' | translate }}</div>
            <div class="m-start-auto text-info small" *ngIf="ooo?.currentlyEnabled()">Out of office</div>
          </a>
        </div>

        <div class="nav-item" [ngClass]="{'active': activeState === 'help-center'}">
          <a href="" class="nav-link" id="sidebar-account-help-center" [routerLink]="['/help-center']">
            <div class="icon rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/assistant-24.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.item_help_center' | translate }}</div>
          </a>
        </div>

        <div class="nav-item">
          <a href="" class="nav-link" id="sidebar-logout" (click)="showLogoutModal($event)">
            <div class="icon rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/logout-2-24.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.item_logout' | translate }}</div>
          </a>
        </div>

        <div class="nav-item item-legal sidebar-open-element-block">
          <a href="" id="button_flow_copyrights_cookie_settings"
            class="btn btn-sm btn-as-text m-end-1 d-inline-flex align-items-center" (click)="triggerCookies($event)">
            <span>{{ 'components.flow_copyrights.cookie_settings' | translate }}</span>
            <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/arrow-right-16.svg'"></span>
          </a>

          <a href="" id="button_flow_copyrights_cookie_page"
            class="btn btn-sm btn-as-text d-inline-flex align-items-center" (click)="goToCookiePolicy($event)">
            <span>{{ 'components.flow_copyrights.cookie_page' | translate }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="layout-body">
    <router-outlet></router-outlet>
  </div>
</div>
