import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { QueryListStatus } from '../../../models/query-list';
import { HcpService } from '../../../services/hcp.service';
import { QueryListService } from '../../../services/query-list.service';
import { TranslateNumberPipe } from '../../../pipes/translate-number.pipe';
import { LanguageService } from '../../../services/language.service';
import { BackButtonData } from './../../../models/back-button-data';
import { GeneralService } from './../../../services/general.service';

@Component({
  selector: 'app-query-list-overview',
  templateUrl: './query-list-overview.component.html'
})
export class QueryListOverviewComponent implements OnInit {
  public patientPathwayUid: string;
  public patientUid: string;
  public hcpUid;
  public filter: string;
  public isLoading: boolean = false;
  public queryLists: Array<any> = [];
  public QueryListStatus = QueryListStatus;

  public currentPage = 1;
  public totalPages: number;
  public totalElements: number;
  public currentPageSize = 10;
  public sorting = 'status,desc,changed_at,desc';

  public backButtonData: BackButtonData;

  constructor(
    public queryListService: QueryListService,
    private route: ActivatedRoute,
    private hcpService: HcpService,
    public translateNumber: TranslateNumberPipe,
    public translateService: TranslateService,
    public languageService: LanguageService,
    public generalService: GeneralService
  ) {

  }

  ngOnInit(): void {
    combineLatest([this.route.params, this.route.queryParams]).subscribe(result => {
      this.patientPathwayUid = result[0].patientPathwayUid;
      this.patientUid = result[0].patientUid;
      this.filter = result[1].filter;
      const backToUrl = result[1]['back-to-url'];

      if (backToUrl) {
        this.backButtonData = this.generalService.defineBackButton(backToUrl);
      }

      this.getQueryLists();
    });
  }

  getQueryLists() {
    this.isLoading = true;
    this.hcpUid = this.hcpService.getCurrentStoredHcpUid();
    this.queryListService.getQueryLists(this.hcpUid, this.patientUid, this.patientPathwayUid, {query_list_type: this.filter}, this.sorting, this.currentPage - 1, this.currentPageSize).subscribe(result => {
      this.isLoading = false;
      this.queryLists = result.queryLists;
      // this.queryLists = [];

      if (result.pagination) {
        this.totalPages = result.pagination.total_pages;
        this.totalElements = result.pagination.total_elements
      }
    });
  }

  grayedOut(form) {
    return (form.status === QueryListStatus.COMPLETED|| form.status === QueryListStatus.TIMED_OUT);
  }

  onPaginationPageChanged(page) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getQueryLists();
    }
  }

  onPaginationRangeChanged(range) {
    if (range !== this.currentPageSize) {
      this.currentPage = 1; // also reset the currentPage
      this.currentPageSize = range;
      this.getQueryLists();
    }
  }

  getLabelForTotalElements(): string {
    const translatedNumber = this.translateNumber.transform(this.totalElements);
    const itemsLabel = (this.totalElements > 1) ?
      this.translateService.instant('components.pagination.items_total') :
      this.translateService.instant('components.pagination.items_total_singular');

    if (this.languageService.getCurrentLanguage().locale === 'he-il' && this.totalElements === 1){
      return itemsLabel + ' ' + translatedNumber;
    }

    return  translatedNumber + ' ' + itemsLabel;
  }
}
