import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MaintenanceService } from '../../../services/maintenance.service';
import { TimeZoneDatePipe } from '../../../pipes/tz_date.pipe';
import { TimePipe } from '../../../pipes/time.pipe';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  public from: string;
  public until: string;
  public unavailable: boolean;

  constructor(
    public maintenanceService: MaintenanceService,
    private router: Router,
    private datePipe: TimeZoneDatePipe,
    private timePipe: TimePipe,
    public authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.loadStatus();
  }

  public loadStatus(): void {
    this.maintenanceService.getMaintenanceStatus().subscribe(result => {
      if (result) {
        this.unavailable = result.unavailable;
        this.from = this.parseDate(result.from);
        this.until = this.parseDate(result.to);
      }

      if (!this.unavailable) {
        // TODO: HIDDEN UNTIL AGIK-6111
        // if (this.authenticationService.hasCcRole()) {
        //   this.router.navigateByUrl('/responses');
        // } else {
        //   this.router.navigateByUrl('/dashboard');
        // }
        this.router.navigateByUrl('/dashboard');
      }
    });
  }

  private parseDate(date?: string): string|null {
    if (date) {
      return new Date(date).toUTCString();
      // return `${this.datePipe.transform(date)} - ${this.timePipe.transform(date)}`;
    } else {
      return null;
    }
  }
}
