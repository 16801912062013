import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Material } from '../models/material';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LanguageService } from './language.service';
import { PathwayService } from './pathway.service';
import { HcpService } from './hcp.service';
import { MaterialPhase } from '../models/material-phase';
import { PhaseInstance } from '../models/phase-instance';
import { TranslationInterface } from '../interfaces/translation.interface';
import { Pathway } from '../models/pathway';

@Injectable({
  providedIn: 'root'
})
export class MaterialService extends ApiService {
  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public pathwayService: PathwayService,
    public authService: AuthenticationService,
    public hcpService: HcpService,
    public languageService: LanguageService,
    public sanitizer: DomSanitizer
  ) {
    super(http, authenticationService);
  }


  getMaterial(id: string, locale: string): Observable<Material> {
    return new Observable(observer => {
      const url = environment.cmsUrl + `/v3/content_types/educational_material/entries/${id}`;
      this.cmsGet(url, locale).subscribe(result => {
        const material = new Material();
        material.fillFromCms(result.entry);

        observer.next(material);
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }

  getTranslatedMaterialsFromPathway(pathway: Pathway): Observable<MaterialPhase[]> {
    const locale = this.languageService.getCurrentLanguage().locale;

    return new Observable(observer => {
      let materialPhases = pathway.educational_materials;
      const materialCmsIds: string[] = [];
      const descriptionCmsIds: TranslationInterface[] = [];

      materialPhases.forEach((materialPhase: MaterialPhase) => {
        descriptionCmsIds.push(...this.getDescriptions(materialPhase, pathway.phases));
        materialCmsIds.push(...this.getMaterialIds(materialPhase));

        materialPhase = this.setDescriptions(materialPhase, pathway.phases);
      });

      const observables = [this.cmsRegionGet('educational_material', locale, null, [...new Set(materialCmsIds)])];

      if (descriptionCmsIds[0]?.region) {
        observables.push(this.cmsRegionGet(descriptionCmsIds[0]?.region, locale, null, [...new Set(descriptionCmsIds.map(id => id.key))]));
      }

      forkJoin(observables).subscribe(([materials, descriptions]) => {
        materialPhases = this.mapTranslationsToMaterialPhases(materialPhases, materials, descriptions);

        observer.next(materialPhases);
        observer.complete();
      }, error => observer.error(error));
    });
  }


  getTranslatedMaterials(patientUid: string, patientPathwayUid: string): Observable<MaterialPhase[]> {
    return new Observable(observer => {
      const pathWayObservable = this.authService.hasCcRole()
        ? this.pathwayService.getDashboardPatientPathwayByHospital(this.hcpService.getCurrentStoredHospitalUid(), patientUid, patientPathwayUid)
        : this.pathwayService.getDashboardPatientPathwayByHcp(this.hcpService.getCurrentStoredHcpUid(), patientUid, patientPathwayUid);

      pathWayObservable.subscribe(pathway => {
        return this.getTranslatedMaterialsFromPathway(pathway).subscribe(materialPhases => {
          observer.next(materialPhases);
          observer.complete();
        });
      }, error => observer.error(error));
    });
  }

  setDescriptions(materialPhase: MaterialPhase, pwPhases: PhaseInstance[]): MaterialPhase {
    const description = pwPhases.find(pwPhase => pwPhase.id === materialPhase.phase_id)?.description;

    if (description) {
      materialPhase.description = description;
    } else {
      for (const phase of pwPhases) {
        if (phase.sub_phase_instances?.length) {
          materialPhase = this.setDescriptions(materialPhase, phase.sub_phase_instances);
        }
      }
    }

    if (materialPhase?.sub_phases?.length) {
      materialPhase.sub_phases.forEach((subPhase: MaterialPhase) => {
        materialPhase = this.setDescriptions(subPhase, pwPhases);
      });
    }

    return materialPhase;
  }

  getMaterialIds(phase: MaterialPhase): string[] {
    const ids = [];

    if (phase?.educational_materials?.length) {
      for (const mat of phase.educational_materials) {
        if (!ids.includes(mat.reference.key)) {
          ids.push(mat.reference.key);
        }
      }
    }

    if (phase?.sub_phases?.length) {
      phase.sub_phases.forEach((subPhase: MaterialPhase) => ids.push(...this.getMaterialIds(subPhase)));
    }

    return ids;
  }

  getDescriptions(materialPhase: MaterialPhase, pwPhases: PhaseInstance[]): TranslationInterface[] {
    const descriptions: TranslationInterface[] = [];

    if (materialPhase?.educational_materials?.length) {
      const description = pwPhases.find(pwPhase => pwPhase.id === materialPhase.phase_id)?.description;

      if (description) {
        descriptions.push(description);
      } else {
        for (const phase of pwPhases) {
          if (phase.sub_phase_instances?.length) {
            descriptions.push(...this.getDescriptions(materialPhase, phase.sub_phase_instances));
          }
        }
      }
    }

    if (materialPhase?.sub_phases?.length) {
      materialPhase.sub_phases.forEach((subPhase: MaterialPhase) => {
        descriptions.push(...this.getDescriptions(subPhase, pwPhases));
      });
    }

    return descriptions;
  }

  mapTranslationsToMaterialPhases(materialPhases: MaterialPhase[], materials: Material[], descriptions): MaterialPhase[] {
    materialPhases.map(materialPhase => {
      return this.mapTranslationToSinlgeMaterialPhase(materialPhase, materials, descriptions);
    });

    return materialPhases;
  }

  mapTranslationToSinlgeMaterialPhase(materialPhase: MaterialPhase, materials: Material[], descriptions): MaterialPhase {
    if (materialPhase.description) {
      const matchDescription = descriptions.find(desc => desc.uid === materialPhase?.description?.key)?.phase_description;
      if (matchDescription) {
        materialPhase.translated_description = matchDescription;
      }
    }

    materialPhase.educational_materials.map((material: Material) => {
      const matchCms: Material = materials.find((materialCms: Material) => materialCms.uid === material.cmsUid);
      return material.fillFromCms(matchCms);
    });

    if (materialPhase.sub_phases?.length) {
      materialPhase.sub_phases.forEach(subPhase => {
        this.mapTranslationToSinlgeMaterialPhase(subPhase, materials, descriptions);
      });
    }

    return materialPhase;
  }
}

