<div class="modal-header">
  <h6 class="h7">{{ 'pages.default.responses_detail.log_action' | translate }}</h6>
  <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body">
  <form *ngIf="form" [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <div class="form-group required">
      <app-select
        [label]="('form.labels.action_taken' | translate)"
        [clearable]="true"
        [searchable]="false"
        [hideSelected]="false"
        [multiple]="false"
        [items]="watchActions"
        [bindValue]="'value'"
        [asFilter]="false"
        [showCheckboxes]="false"
        formControlName="actionTaken"
      ></app-select>
      <app-feedback-field [field]="form.get('actionTaken')"></app-feedback-field>
    </div>

    <div class="form-group">
      <div class="d-flex justify-content-between mb-1">
        <label for="inputDescription">{{ 'form.labels.description' | translate }}</label>

        <p class="extra-small text-muted m-0"
           *ngIf="!(validationVisible && !form?.controls?.description?.value)">
          {{ ((form?.controls?.description?.value?.length || '0') | translateNumber) }}
          /
          {{ descriptionMaxLength | translateNumber }}
        </p>
      </div>

      <textarea appTextareaAutoresize id="inputDescription" class="form-control w-100" formControlName="description"
                placeholder=""
                [maxlength]="descriptionMaxLength"></textarea>

      <app-feedback-field [field]="form.get('description')"></app-feedback-field>
    </div>

    <div class="form-group">

      <div class="d-flex align-items-center justify-content-between mb-2">
        <label class="required">{{ 'modals.pathway_monitor_action_modal.responses' | translate }}</label>

        <button id="responses-action-select-all" class="btn btn-link" (click)="selectAll()">
          @if (allWatchesSelected) {
            {{ 'modals.pathway_monitor_action_modal.deselect_all' | translate }}
          } @else {
            {{ 'modals.pathway_monitor_action_modal.select_all' | translate }}
          }
        </button>
      </div>

      <div class="responses">
        @for (watch of watches; track watch.id) {
          <button class="participant-item clickable mb-1"
                  [ngClass]="{'selected': form.get('watchUids')?.value?.includes(watch.id), 'error': form.get('watchUids')?.invalid && validationVisible}"
                  (click)="onMultiValueChange(watch)">
            <div class="meta">
              <p class="title">{{ watch.titleTranslationKey | translate }}</p>
              <p class="description">
                {{ watch.acknowledged_at | timeAgo:locale }}
              </p>
            </div>

            <div class="checkbox">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" [id]="watch.id" [name]="watch.id"
                       [checked]="form.get('watchUids')?.value?.includes(watch.id)"
                       (change)="onMultiValueChange(watch)">
                <label class="form-check-label" [for]="watch.id"></label>
              </div>
            </div>
          </button>
        }
      </div>
      <app-feedback-field [field]="form.get('watchUids')" extraClass="mb-0 general-feedback"/>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" id="pathway-monitor-action-modal-cancel"
            (click)="bsModalRef.hide()">{{ 'action.cancel' | translate }}
    </button>
    <button class="btn btn-primary m-start-2" id="pathway-monitor-action-modal-confirm" (click)="handleSubmit()"
            [ngClass]="{'loader': isSubmitting}">
      <span class="loader" *ngIf="isSubmitting" [inlineSVG]="'./assets/svg/loader-dark-sm.svg'"></span>
      <span>{{ 'action.submit' | translate }}</span>
    </button>
  </div>
</div>
