import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';
import { PathwayMonitor } from '../models/pathway-monitor';
import { PagingDto } from '../interfaces/paging-dto';
import { HcpService } from '../services/hcp.service';
import { PathwayMonitorWatchAction } from '../enums/pathway-monitor-watch-action';

interface PathwayMonitorPostBody {
  action_type: string;
  watch_ids: string[];
  action?: PathwayMonitorWatchAction;
  description?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PathwayMonitorService extends ApiService {
  hcpService = inject(HcpService);

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
  ) {
    super(http, authenticationService);
  }

  getPathwayMonitorsForHospital(
    filters: {
      patientLastName: string | null,
      careModuleUids: string[] | null
    },
    page = 0,
    size = 2000): Observable<PagingDto<PathwayMonitor[]>> {
    const hospital_uid = this.hcpService.getCurrentStoredHospitalUid();

    const paramBits = [
      `page=${String(page)}`,
      `size=${String(size)}`
    ];

    if (filters.patientLastName) {
      paramBits.push(`last_name=${filters.patientLastName}`);
    }

    if (filters.careModuleUids) {
      filters.careModuleUids.forEach(uid => paramBits.push(`care_module_uid=${uid}`));
    }

    const url = `${environment.platformUrl}/hospitals/${hospital_uid}/pathway-monitors?${paramBits.join('&')}`;
    // const url = `${environment.platformUrl}/hospitals/${hospital_uid}/pathway-monitors`;


    return new Observable(observer => {
      this.authenticatedGet(url).subscribe({
        next: results => {
          observer.next({
            items: results.items?.map(result => new PathwayMonitor(result)),
            pagination: results?.pagination
          });
          observer.complete();
        },
        error: error => {
          observer.error(error);
          observer.complete();
        }
      });
    });
  }

  getPathwayMonitorDetailForHospital(monitorUid): Observable<PathwayMonitor> {
    const hospital_uid = this.hcpService.getCurrentStoredHospitalUid();
    const url = `${environment.platformUrl}/hospitals/${hospital_uid}/pathway-monitors/${monitorUid}`;

    return new Observable(observer => {
      this.authenticatedGet(url).subscribe({
        next: result => {
          observer.next(new PathwayMonitor(result));
          observer.complete();
        },
        error: error => {
          observer.error(error);
          observer.complete();
        }
      });
    });
  }

  acknowledgeWatches(monitorUid: string, ids: string[]): Observable<void> {
    return this.postPathwayMonitorForHospital(monitorUid, 'ACKNOWLEDGE_RECORD', ids);
  }

  actionWatches(
    monitorUid: string,
    ids: string[],
    watchAction: PathwayMonitorWatchAction,
    description: string
  ): Observable<void> {
    return this.postPathwayMonitorForHospital(monitorUid, 'ACTION_RECORD', ids, watchAction, description);
  }

  postPathwayMonitorForHospital(
    monitorUid: string,
    action: 'ACKNOWLEDGE_RECORD' | 'ACTION_RECORD',
    ids: string[],
    watchAction?: PathwayMonitorWatchAction,
    description?: string
  ): Observable<void> {
    const hospital_uid = this.hcpService.getCurrentStoredHospitalUid();
    const url = `${environment.platformUrl}/hospitals/${hospital_uid}/pathway-monitors/${monitorUid}`;

    const data: PathwayMonitorPostBody = {
      action_type: action,
      watch_ids: ids
    };

    if (action === 'ACTION_RECORD') {
      data.action = watchAction;
      data.description = description;
    }

    return new Observable(observer => {
      this.authenticatedPost(url, data).subscribe({
        next: () => {
          observer.next();
          observer.complete();
        },
        error: error => {
          observer.error(error);
          observer.complete();
        }
      });
    });
  }
}
