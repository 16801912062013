<div class="heading-block">
  <h5>{{ 'pages.default.patient_detail.zone_' + identifier | translate }}</h5>

  <a href="" [id]="'show_more_' + identifier" class="btn btn-secondary block-action" [routerLink]="['/patient/' + patientUid + '/' + pathwayUid + '/query-lists']" [queryParams]="{filter: type}" >
    {{ 'pages.default.query_lists.details' | translate }}
  </a>
</div>

<div class="bg-white px-2 py-2" *ngIf="loading">
  <app-page-loader></app-page-loader>
</div>

<div *ngIf="!loading">
  @if (queryLists?.length) {
    <div class="row">
      <div class="col-4 mb-2" *ngFor="let form of queryLists">
        <div class="card h-100" [ngClass]="{'card-muted': form.status === QueryListStatus.COMPLETED || form.status === QueryListStatus.TIMED_OUT}">
          <div class="card-header">
            <a href="" [routerLink]="['/patient/' + patientUid + '/' + pathwayUid + '/' + '/query-lists/' + form.uid]" [queryParams]="{filter: type}">{{ form.title | translateHelp | translate }}</a>
          </div>

          <div class="card-body">
            <div class="user-compact" *ngIf="form.candidate_users.length < 2">
              <app-avatar [profilePicture]="form.assignee?.profile_picture"></app-avatar>

              <div class="user-compact-info">
                <p class="title text-truncate">{{ form.assignee?.getFullName() }}</p>
                <p *ngIf="form.assignee_type !== 'PATIENT' && form.assignee?.uid === hcpUid">{{ 'pages.default.query_lists.you' | translate }}</p>
                <p *ngIf="form.assignee_type !== 'PATIENT' && form.assignee?.uid !== hcpUid">{{ form.assignee?.job_title }}</p>
                <p *ngIf="form.assignee_type === 'PATIENT'">{{ 'pages.default.query_lists.patient' | translate }}</p>
              </div>
            </div>

            @if (form.candidate_users.length > 1) {
              <app-avatar class="m-end-1 mt-2" [profilePicture]="user?.profile_picture" *ngFor="let user of form.sortedCandidateUsers(hcpUid)"></app-avatar>
            }

            <p class="text-muted small mb-0 mt-2" *ngIf="form.status === QueryListStatus.COMPLETED">
              {{ 'pages.default.query_lists.submitted_on' | translate }} {{ form.completed_at | timeZoneDate }}
            </p>
            <p class="text-muted small mb-0 mt-2" *ngIf="form.status === QueryListStatus.TIMED_OUT">
              {{ 'pages.default.query_lists.due_at' | translate : {value:form.due_days_ago?.toString() } }}
            </p>

          </div>

          <div class="card-footer">

            @switch (form.status) {

              @case (QueryListStatus.NEW) {
                <div class="status-badge success">
                  <span class="icon xs rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/dot-24.svg'"></span>
                  {{ 'pages.default.query_lists.status.new' | translate }}
                </div>
              }

              @case (QueryListStatus.SAVED) {
                <div class="status-badge in-progress">
                  {{ 'pages.default.query_lists.status.saved' | translate }}
                </div>
              }

              @case (QueryListStatus.COMPLETED) {
                <div class="status-badge">
                  {{ 'pages.default.query_lists.status.completed' | translate }}
                </div>
              }

              @case (QueryListStatus.TIMED_OUT) {
                <div class="status-badge">
                  {{ 'pages.default.query_lists.status.timed_out' | translate }}
                </div>
              }
            }

            <a href="" class="btn btn-tertiary btn-icon m-start-auto" [routerLink]="['/patient/' + patientUid + '/' + pathwayUid + '/' + '/query-lists/' + form.uid]" [queryParams]="{filter: type}">
              <span class="rtl-mirrored-inline-svg" [inlineSVG]="'./assets/svg/arrow-right-32.svg'"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  }

  @else {
    <div class="bg-white px-2 py-4 rounded-2">
      <p class="m-0 text-center text-muted">
        {{ 'pages.default.patient_detail.patient_no_' + identifier | translate }}
      </p>
    </div>
  }
</div>

@if (totalChecklists > 3) {
  <p class="text-end">
    <a href="" [id]="'link_' + identifier + '_show_more'" class="btn btn-link" [routerLink]="['/patient/' + patientUid + '/' + pathwayUid + '/query-lists']" [queryParams]="{filter: type}" >
      <span class="label">{{ 'pages.default.patient_detail.show_all' | translate }} ({{ totalChecklists }})</span>
    </a>
  </p>
}
