import { TranslationInterface } from '../interfaces/translation.interface';
import { Material } from './material';
import { PhaseType } from './phase-instance';

export class MaterialPhase {
  public name: TranslationInterface;
  public description: TranslationInterface;
  public translated_description: string;
  public total = 0;
  public completed = 0;
  public educational_materials: Material[];
  public phase_id: string;
  public sub_phases: MaterialPhase[] = [];
  public type: PhaseType;


  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    if (item.phase) {
      this.name = item.phase.name;
      this.phase_id = item.phase.id;
    }

    this.description = item.description;
    this.translated_description = item.translated_description;

    this.total = item.total;
    this.completed = item.completed?.individual;
    this.type = item.type ? item.type : PhaseType.PHASE;
    this.educational_materials = [];

    // load educational material for the highest level
    item.educational_materials?.forEach(result => {
      const material = new Material(result);
      material.phaseName = this.name;
      this.educational_materials.push(material);
    });

    // load educational material for all lower levels
    if (item.sub_phases && item.sub_phases.length) {

      for (const subPhase of item.sub_phases) {
        subPhase.type = PhaseType.SUBPHASE;
        this.sub_phases.push(new MaterialPhase(subPhase));
      }
    }

    this.educational_materials = this.educational_materials?.sort(this.order);

  }

  order(a: Material, b: Material) {
    if (a.progress < b.progress) {
      return -1;
    }
    if (a.progress > b.progress) {
      return 1;
    }


    if (a.progress === b.progress) {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
    }
    return 0;
  }

  get percentage(): string {
    return `${this.total && this.completed ? (this.completed / this.total) * 100 : 0}%`;
  }

  get isFinished(): boolean {
    return ((this.total > 0) && (this.completed >= this.total));
  }

  public get isSubPhase(): boolean {
    return this.type === PhaseType.SUBPHASE;
  }

  public get translationKey(): string {
    return `shared.${this.name?.region}.${this.name?.key}`;
  }
}
