import { TranslationInterface } from '../interfaces/translation.interface';
import { PathwayMonitorGrade } from '../enums/pathway-monitor-grade';
import { WatchDetail } from '../interfaces/monitor-watch-detail';

export class Monitor {
  grade: PathwayMonitorGrade;
  title: TranslationInterface;
  monitor_id: string;
  added_at: string;
  total_watches: number;
  total_watches_open: number;
  total_watches_acknowledged: number;
  total_watches_actioned: number;
  oldest_non_actioned_watch: WatchDetail;
  newest_non_actioned_watch: WatchDetail;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.monitor_id = item.monitor_id;
    this.grade = item.grade;
    this.title = item.title;
    this.added_at = item.added_at;
    this.total_watches = item.total_watches;
    this.total_watches_open = item.total_watches_open;
    this.total_watches_acknowledged = item.total_watches_acknowledged;
    this.total_watches_actioned = item.total_watches_actioned;
    this.oldest_non_actioned_watch = item.oldest_non_actioned_watch as WatchDetail;
    this.newest_non_actioned_watch = item.newest_non_actioned_watch as WatchDetail;
  }

  get titleTranslationKey(): string {
    return `shared.${this.title.region}.${this.title.key}`;
  }
}
