import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { HealthCareProfessional } from '../models/health-care-professional';
import { TranslateService } from '@ngx-translate/core';
import { HcpFunction } from '../../app/models/hcp-function';
import { DataService } from './data.service';
import { GeneralService } from './general.service';
import { CareModule } from '../models/care-module';
import { PagingDto } from '../interfaces/paging-dto';

@Injectable({
  providedIn: 'root'
})
export class HcpService extends ApiService {
  private readonly platformUrl: string;

  public static StorageKeyCurrentHcp = 'C4T_current_hcp';
  public static StorageKeyCurrentCareModules = 'C4T_current_care_modules';


  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public dataService: DataService,
    public translateService: TranslateService,
    public generalService: GeneralService
  ) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  private static puttableHcp(hcp: HealthCareProfessional): object {
    if (hcp == null) {
      return null;
    }
    return {
      first_name: hcp.first_name,
      last_name: hcp.last_name,
      phone_number: hcp.phone_number,
      gender: hcp.gender,
      profile_picture: hcp.profile_picture,
      language: hcp.language,
      location: hcp.location,
      status: hcp.status,
      job_title: hcp.job_title,
      function: hcp.function
    };
  }

  getPaged(
    filters: any = {},
    sort = 'last_name,asc',
    page = 0,
    size = 30
  ): Observable<PagingDto<HealthCareProfessional>> {
    const hospitalUid = this.getCurrentStoredHospitalUid();

    return new Observable(observer => {
      const paramBits = [
        `page=${String(page)}`,
        `size=${String(size)}`
      ];

      if (sort) {
        paramBits.push(`sort=${sort}`);
      }

      /*
      ** Filter - Last Name - Start
      */
      let last_name = filters.last_name || filters.hcpLastName
      if (last_name) {
        paramBits.push(`last_name=${last_name}`);
      }
      /*
      ** Filter - Last Name - End
      */


      /*
      ** Filter - Status - Start
      */
      if (filters?.status) {
        paramBits.push(`status=${filters.status}`);
      }
      /*
      ** Filter - Status - end
      */


      /*
      ** Filter - Function Uid(s) - Start
      */
      let function_key = filters.function_key || filters.function_keys || filters.functionkeys;
      if (function_key) {
        if (Array.isArray(function_key)) {
          function_key.forEach(cmu => {
            paramBits.push(`function=${cmu}`);
          });
        } else {
          paramBits.push(`function=${function_key}`);
        }
      }
      /*
      ** Filter - Function Uid(s) - End
      */


      const paramsString = paramBits.join('&');
      const url = `${environment.platformUrl}/hospitals/${hospitalUid}/hcps?${paramsString}`;
      this.authenticatedGet(url).subscribe(result => {
        const hcps: HealthCareProfessional[] = [];
        for (const item of result?.items) {
          const hcp = new HealthCareProfessional(item);
          hcps.push(hcp);
        }

        observer.next({ items: hcps, pagination: result?.pagination });
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  get(id: string): Observable<HealthCareProfessional> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hcps/${id}`;
      this.authenticatedGet(url).subscribe(result => {
        const hcp = new HealthCareProfessional(result);
        observer.next(hcp);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getByHospital(id: string, hospital_uid): Observable<HealthCareProfessional> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hospitals/${hospital_uid}/hcps/${id}`;
      this.authenticatedGet(url).subscribe(result => {
        const hcp = new HealthCareProfessional(result);
        observer.next(hcp);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  updateForHcpRole(hcp: HealthCareProfessional): Observable<HealthCareProfessional> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hcps/${hcp.uid}`;
      const params = this.postableHCP(hcp);

      let profilePictureData: string;
      if (hcp.profile_picture && hcp.profile_picture.data) {
        profilePictureData = hcp.profile_picture.data;
        hcp.profile_picture.data = hcp.profile_picture.data.split(',')[1];
      }

      if (params['location']['name'] === "") {
        delete params['location'];
      }

      this.authenticatedPut(url, params).subscribe(result => {
        hcp = new HealthCareProfessional(result);

        if (profilePictureData) {
          this.dataService.set(`PROFILE_PICTURE_${hcp.profile_picture.uuid}`, profilePictureData);
        }

        observer.next(hcp);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  updateForCcRole(hcp: HealthCareProfessional): Observable<HealthCareProfessional> {
    const puttableHcp = HcpService.puttableHcp(hcp);
    const hospitalUid = this.getCurrentStoredHospitalUid();

    let profilePictureData: string;
    if (hcp.profile_picture && hcp.profile_picture.data) {
      profilePictureData = hcp.profile_picture.data;
      hcp.profile_picture.data = hcp.profile_picture.data.split(',')[1];
    }

    if (puttableHcp['location']['name'] === "") {
      delete puttableHcp['location'];
    }

    const url = `${environment.platformUrl}/hospitals/${hospitalUid}/hcps/${hcp.uid}`;
    return new Observable(observer => {
      this.authenticatedPut(url, puttableHcp).subscribe(result => {
        hcp = new HealthCareProfessional(result);

        if (profilePictureData) {
          this.dataService.set(`PROFILE_PICTURE_${hcp.profile_picture.uuid}`, profilePictureData);
        }
        observer.next(hcp);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  changeEmailAddressForHcp(
    hospitalUid: string,
    hcpUid: string,
    new_email: string,
    reason: string
  ): Observable<any>{
    const url = `${environment.platformUrl}/hospitals/${hospitalUid}/hcps/${hcpUid}/change-email`;

    const data = {
      new_email,
      reason
    }

    return new Observable(observer => {
      this.authenticatedPost(url, data).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  postableHCP(hcp: HealthCareProfessional): Object {

    const data = {
      first_name: hcp.first_name,
      last_name: hcp.last_name,
      phone_number: hcp.phone_number,
      gender: hcp.gender,
      language: hcp.language,
      location: hcp.location,
      status: hcp.status,
      is_care_coordinator: hcp.is_care_coordinator,
      reviewed: hcp.reviewed,
      profile_picture: hcp.profile_picture,
      job_title: hcp.job_title
    };

    return data;
  }

  getFunctions(hospital_uid): Observable<HcpFunction[]> {
    const urlParts = [];
    const urlParams = [];

    urlParts.push(`${this.platformUrl}/hospitals/${hospital_uid}/functions`);

    urlParams.push(`page=0`);
    urlParams.push(`size=999`);

    urlParts.push(`?${urlParams.join('&')}`);
    const url = urlParts.join('');

    return new Observable(observer => {
      this.authenticatedGet(url, null, true).subscribe(result => {
        const functions: HcpFunction[] = result['items'].map(func => new HcpFunction(func));
        this.generalService.sortByKey(functions, 'key');

        observer.next(functions);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }


  //
  // Important for scope service - Start
  //

  // // This will load a hcp by uid and store in storage as current hcp
  public setCurrentHcp(uid: string): Observable<HealthCareProfessional> {
    return new Observable((observer) => {
      this.get(uid).subscribe(hcp => {
        this.storeCurrentHcpByModel(hcp);
        observer.next(hcp);
        observer.complete();
      });
    });
  }

  // // Will store a hcp in storage as current hcp
  public storeCurrentHcpByModel(hcp: HealthCareProfessional) {
    localStorage.setItem(HcpService.StorageKeyCurrentHcp, JSON.stringify(hcp));
  }

  // // Will retrieve the current hcp model from storage
  public getCurrentStoredHcp(): HealthCareProfessional {
    const storedHcp = localStorage.getItem(HcpService.StorageKeyCurrentHcp);

    if (storedHcp) {
      const hcp: HealthCareProfessional = new HealthCareProfessional();
      hcp.fromStorage(JSON.parse(storedHcp) as HealthCareProfessional);
      return hcp;
    } else {
      return null;
    }
  }

  // // Will retrieve the current hcp uid from storage
  public getCurrentStoredHcpUid(): string {
    const hcp: HealthCareProfessional = this.getCurrentStoredHcp();

    if (hcp) {
      return hcp.uid;
    } else {
      return undefined;
    }
  }

  // // Will retrieve the current hcp hospital uid from storage
  public getCurrentStoredHospitalUid(): string {
    const hcp: HealthCareProfessional = this.getCurrentStoredHcp();

    if (hcp) {
      return hcp.hospital_uid;
    } else {
      return undefined;
    }
  }

  //
  // Important for scope service - End
  //


  public storeCurrentCareModules(modules: CareModule[]) {
    localStorage.setItem(HcpService.StorageKeyCurrentCareModules, JSON.stringify(modules));
  }

  public getCurrentStoredCareModules(): CareModule[] {
    const careModule = localStorage.getItem(HcpService.StorageKeyCurrentCareModules);
    return JSON.parse(careModule);
  }
}
