import { Component, inject, OnInit } from '@angular/core';
import { PathwayMonitorService } from '../../../services/pathway-monitor.service';
import { Patient } from 'src/app/models/patient';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CareModule } from '../../../models/care-module';
import { PathwayMonitor } from '../../../models/pathway-monitor';
import { HospitalService } from '../../../services/hospital.service';
import { HcpService } from '../../../services/hcp.service';
import { DateFormat } from '../../../models/date-format';
import { LocaleService } from '../../../services/locale.service';
import { Router } from '@angular/router';
import moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-responses-overview',
  templateUrl: './responses-overview.component.html',
})
export class ResponsesOverviewComponent implements OnInit {
  public pathwayMonitorService = inject(PathwayMonitorService);
  public hospitalService = inject(HospitalService);
  public hcpService = inject(HcpService);
  public localeService = inject(LocaleService);
  public router = inject(Router);
  public translateService = inject(TranslateService);

  public dateFormat: DateFormat;
  public locale = this.localeService.getLocalePreferences().locale;

  public pathwayMonitors: PathwayMonitor[] = [];

  public patients: Array<Patient>;
  public patientLastNameSearch = new Subject<string>();

  public careModules: CareModule[] = [];

  public filters: { patientLastName: string | null, careModuleUids: string[] | null } = {
    patientLastName: null,
    careModuleUids: null
  };

  public totalElements: number;

  public isLoading = false;
  public isLoadingCareModules = false;

  get filtersAreSet(): boolean {
    return (this.filters.patientLastName !== null && this.filters.patientLastName.length > 0)
      || this.filters.careModuleUids !== null;
  }

  ngOnInit() {
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;

    this.getPathwayMonitors();

    this.patientLastNameSearch.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.filters.patientLastName = value;

        this.getPathwayMonitors();
      });
  }

  getCareModules(): void {
    this.isLoadingCareModules = true;

    this.hospitalService.getCareModules(this.hcpService.getCurrentStoredHospitalUid()).subscribe({
      next: careModules => {
        this.careModules = careModules;
        this.isLoadingCareModules = false;
      }, error: () => this.isLoadingCareModules = false
    });
  }

  getPathwayMonitors(showLoading = true) {
    if (showLoading) {
      this.isLoading = true;
    }

    this.pathwayMonitorService.getPathwayMonitorsForHospital(this.filters).subscribe({
      next: result => this.handlePathwayMonitors(result),
      error: () => this.isLoading = false
    });
  }

  handlePathwayMonitors(result) {
    this.pathwayMonitors = result.items;
    this.totalElements = result.pagination?.total_elements;
    this.isLoading = false;
  }

  onFilterChange() {
    this.getPathwayMonitors(false);
  }

  actionResetFilters() {
    this.filters = {
      patientLastName: null,
      careModuleUids: null
    };

    this.getPathwayMonitors(false);
  }

  daysSinceSurgery(pathwayMonitor: PathwayMonitor): string {
    if (!pathwayMonitor.surgery_date) {
      return '-';
    }

    const date = moment(pathwayMonitor.surgery_date).tz(this.locale.time_zone);
    const now = moment();

    if (date.isSame(now, 'day')) {
      return this.translateService.instant('pages.default.responses.today');
    }

    return `${now.diff(date, 'days').toString()} ${this.translateService.instant('pages.default.responses.days')}`;
  }

  daysSinceDischarge(pathwayMonitor: PathwayMonitor): string {
    if (!pathwayMonitor.discharge_date) {
      return '-';
    }

    const date = moment(pathwayMonitor.discharge_date).tz(this.locale.time_zone);
    const now = moment();
    const diffInHours = now.diff(date, 'hours');

    if (date.startOf('day').isAfter(now.startOf('day'))) {
      return '-';
    }

    if (diffInHours < 24) {
      return this.translateService.instant('time.hours_ago', {
        value: diffInHours.toString()
      });
    }

    return `${now.diff(date, 'days').toString()} ${this.translateService.instant('pages.default.responses.days')}`;
  }
}
