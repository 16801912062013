import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PathwayMonitorService } from '../../../services/pathway-monitor.service';
import { DataService } from '../../../services/data.service';
import { PathwayMonitor } from '../../../models/pathway-monitor';
import { DateFormat } from '../../../models/date-format';
import { LocaleService } from '../../../services/locale.service';
import { PathwayMonitorWatch } from '../../../models/pathway-monitor-watch';
import { GeneralService } from '../../../services/general.service';
import { ModalService } from '../../../services/modal.service';
import {
  PathwayMonitorActionModalComponent
} from '../../../modals/pathway-monitor-action-modal/pathway-monitor-action-modal.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment-timezone';

@Component({
  selector: 'app-responses-detail',
  templateUrl: './responses-detail.component.html'
})
export class ResponsesDetailComponent implements OnInit {
  activatedRoute = inject(ActivatedRoute);
  pathwayMonitorService = inject(PathwayMonitorService);
  dataService = inject(DataService);
  localeService = inject(LocaleService);
  router = inject(Router);
  modalService = inject(ModalService);
  toastrService = inject(ToastrService);
  translate = inject(TranslateService);

  public pathwayMonitor: PathwayMonitor;
  public monitorUid: string;
  public isLoading = false;
  public isAcknowledging = false;
  public dateFormat: DateFormat;
  public locale = this.localeService.getLocalePreferences().locale;

  ngOnInit() {
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;

    this.activatedRoute.params.subscribe(params => {
      this.monitorUid = params.monitorUid;
      this.getMonitorDetail();
    });
  }

  getMonitorDetail(showLoading = true) {
    if (showLoading) {
      this.isLoading = true;
    }

    this.pathwayMonitorService.getPathwayMonitorDetailForHospital(this.monitorUid).subscribe({
      next: pathwayMonitor => {
        this.pathwayMonitor = pathwayMonitor;
        this.dataService.set(DataService.BreadCrumbMonitorName, this.pathwayMonitor.titleTranslationKey);

        this.isLoading = false;
      },
      error: () => this.isLoading = false
    });
  }

  acknowledgeWatches() {
    this.isAcknowledging = true;
    this.pathwayMonitorService.acknowledgeWatches(
      this.monitorUid,
      this.pathwayMonitor.watches.map(watch => watch.id)
    ).subscribe({
      next: () => {
        this.getMonitorDetail(false);
        this.isAcknowledging = false;
      },
      error: () => this.isAcknowledging = false
    });
  }

  logAction() {
    const initialState = {
      watches: this.pathwayMonitor.watches?.filter(watch => watch.isAcknowledged),
      monitorUid: this.monitorUid
    };

    const modalref = this.modalService.show(PathwayMonitorActionModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg',
        initialState
      })
    );

    modalref.content.onSubmitted.subscribe(watchesCount => {
      this.toastrService.success(this.translate.instant('modals.pathway_monitor_action_modal.success_toast', { value: watchesCount }));
      this.getMonitorDetail();
    });
  }

  isDisabled(watch: PathwayMonitorWatch): boolean {
    return watch.isNew;
  }

  daysSinceSurgery(pathwayMonitor: PathwayMonitor): string {
    if (!pathwayMonitor.surgery_date) {
      return '-';
    }

    const date = moment(pathwayMonitor.surgery_date).tz(this.locale.time_zone);
    const now = moment();

    if (date.isSame(now, 'day')) {
      return this.translate.instant('pages.default.responses.today');
    }

    if (date.startOf('day').isAfter(now.startOf('day'))) {
      return this.translate.instant('pages.default.responses.planned');
    }

    return `${now.diff(date, 'days').toString()} ${this.translate.instant('pages.default.responses.days_ago')}`;
  }

  daysSinceDischarge(pathwayMonitor: PathwayMonitor): string {
    if (!pathwayMonitor.discharge_date) {
      return '-';
    }

    const date = moment(pathwayMonitor.discharge_date).tz(this.locale.time_zone);
    const now = moment();
    const diffInHours = now.diff(date, 'hours');

    if (date.startOf('day').isAfter(now.startOf('day'))) {
      return this.translate.instant('pages.default.responses.planned');
    }

    if (diffInHours < 24) {
      return this.translate.instant('time.hours_ago', {
        value: diffInHours.toString()
      });
    }

    return `${now.diff(date, 'days').toString()} ${this.translate.instant('pages.default.responses.days')}`;
  }
}

