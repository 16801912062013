import { TranslationInterface } from '../interfaces/translation.interface';
import { PathwayMonitorWatchAction } from '../enums/pathway-monitor-watch-action';
import { HealthCareProfessional } from './health-care-professional';
import { FormFieldValue } from './form-field-value';
import { PathwayMonitorWatchType } from '../enums/pathway-monitor-watch-type';
import moment from 'moment/moment';


export class PathwayMonitorWatch {
  id: string;
  added_at: string;
  title: TranslationInterface;
  message: TranslationInterface;
  variables: FormFieldValue;
  acknowledged_by: HealthCareProfessional;
  acknowledged_at: string;
  action: PathwayMonitorWatchAction;
  action_description: string;
  actioned_by: HealthCareProfessional;
  actioned_at: string;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.id = item.id;
    this.added_at = item.added_at;
    this.title = item.title;
    this.message = item.message;
    this.variables = item.variables;

    this.acknowledged_by = item.acknowledged_by ?
      new HealthCareProfessional(item.acknowledged_by) :
      undefined;

    this.acknowledged_at = item.acknowledged_at;
    this.action = item.action;
    this.action_description = item.action_description;
    this.actioned_by = item.actioned_by ?
      new HealthCareProfessional(item.actioned_by) :
      undefined;
    this.actioned_at = item.actioned_at;
  }

  get titleTranslationKey() {
    return `shared.${this.title.region}.${this.title.key}`;
  }

  get messageTranslationKey() {
    return `shared.${this.message.region}.${this.message.key}`;
  }

  get watchType(): PathwayMonitorWatchType {
    if (this.actioned_by && this.actioned_at) {
      return PathwayMonitorWatchType.ACTIONED;
    }

    if (this.acknowledged_by && this.acknowledged_at) {
      return PathwayMonitorWatchType.ACKNOWLEDGED;
    }

    return PathwayMonitorWatchType.NEW;
  }

  get isNew(): boolean {
    return this.watchType === PathwayMonitorWatchType.NEW;
  }

  get isAcknowledged(): boolean {
    return this.watchType === PathwayMonitorWatchType.ACKNOWLEDGED;
  }

  get isActioned(): boolean {
    return this.watchType === PathwayMonitorWatchType.ACTIONED;
  }

  get isAddedToday(): boolean {
    return this.isToday(this.added_at);
  }

  get isActionedToday(): boolean {
    return this.isToday(this.actioned_at);
  }

  get isAcknowledgedToday(): boolean {
    return this.isToday(this.acknowledged_at);
  }

  private isToday(dateString): boolean {
    const date = moment(dateString);
    const now = moment();

    return date.isSame(now, 'day');
  }
}
