import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment-timezone';
import 'moment/min/locales';
import { BaseLocaleInterface } from '../interfaces/base-locale.interface';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {
  constructor(public translateService: TranslateService) {
  }

  transform(dateString: string, locale: BaseLocaleInterface): string {
    if (!dateString) {
      return '';
    }

    const date = moment(dateString);
    const now = moment();

    if (date.isSame(now, 'day')) {
      const format = locale.time_24_hours ? 'HH:mm' : 'LT';
      return date.tz(locale.time_zone).format(format);
    }

    const diffInMinutes = now.diff(date, 'minutes');

    if (diffInMinutes < 60) {
      return this.translateService.instant('time.minutes_ago', {
        value: diffInMinutes.toString()
      });
    }

    const roundedHours = Math.round(diffInMinutes / 60);

    if (roundedHours < 24) {
      return this.translateService.instant('time.hours_ago', {
        value: roundedHours.toString()
      });
    }

    return `${now.diff(date, 'days').toString()} ${this.translateService.instant('pages.default.responses.days_ago')}`;
  }
}
