import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PathwayMonitorWatch } from '../../models/pathway-monitor-watch';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PathwayMonitorWatchAction } from '../../enums/pathway-monitor-watch-action';
import { LocaleService } from '../../services/locale.service';
import { PathwayMonitorService } from '../../services/pathway-monitor.service';

@Component({
  selector: 'app-pathway-monitor-action-modal',
  templateUrl: './pathway-monitor-action-modal.component.html'
})
export class PathwayMonitorActionModalComponent implements OnInit {
  bsModalRef = inject(BsModalRef);
  pathwayMonitorService = inject(PathwayMonitorService);
  localeService = inject(LocaleService);

  public locale = this.localeService.getLocalePreferences().locale;

  watchActions = Object.values(PathwayMonitorWatchAction).map(
    action => {
      return {
        value: action,
        translationKey: `pathway_monitor.watch.action.${action.toLowerCase()}`
      };
    });
  validationVisible = false;
  watches: PathwayMonitorWatch[];
  monitorUid: string;
  form: FormGroup = new FormGroup({
    actionTaken: new FormControl(null, Validators.required),
    description: new FormControl(null),
    watchUids: new FormControl([], Validators.required),
  });

  descriptionMaxLength = 255;
  isSubmitting = false;

  @Output() public onSubmitted: EventEmitter<boolean> = new EventEmitter();

  get allWatchesSelected(): boolean {
    return this.watches?.length === this.form.get('watchUids')?.value?.length;
  }

  ngOnInit() {
    this.form.get('watchUids')?.setValue(this.watches?.map(watch => watch.id));
    this.form.get('watchUids')?.updateValueAndValidity();
  }

  handleSubmit() {
    if (this.form.invalid) {
      this.validationVisible = true;
      return;
    }

    this.validationVisible = false;
    this.isSubmitting = true;

    this.pathwayMonitorService.actionWatches(
      this.monitorUid,
      this.form.get('watchUids').value,
      this.form.get('actionTaken').value,
      this.form.get('description').value
    ).subscribe({
      next: () => {
        this.onSubmitted.emit(this.form.get('watchUids').value?.length);
        this.bsModalRef.hide();
        this.isSubmitting = false;
      },
      error: () => this.isSubmitting = false
    });
  }

  onMultiValueChange(watch: PathwayMonitorWatch) {
    const watchUidsControl = this.form.get('watchUids');
    if (!watchUidsControl) {
      return;
    }

    const currentUids = watchUidsControl.value ?? [];
    const updatedUids = currentUids.includes(watch.id)
      ? currentUids.filter(uid => uid !== watch.id)
      : [...currentUids, watch.id];

    watchUidsControl.setValue(updatedUids);
    watchUidsControl.updateValueAndValidity();
  }

  selectAll() {
    let allUids = this.watches?.map(watch => watch.id);

    if (this.allWatchesSelected) {
      allUids = [];
    }

    this.form.get('watchUids')?.setValue(allUids);
    this.form.get('watchUids')?.updateValueAndValidity();
  }
}
