import { Patient } from './patient';
import { Monitor } from './monitor';
import { CareModule } from './care-module';
import { TranslationInterface } from '../interfaces/translation.interface';
import { PathwayMonitorWatch } from './pathway-monitor-watch';

export class PathwayMonitor {
  // Shared
  patient: Patient;
  care_module: CareModule;
  patient_pathway_uid: string;
  surgery_date: string;
  discharge_date: string;

  // Overview
  monitors: Monitor[];

  // Details
  title: TranslationInterface;
  message: TranslationInterface;
  changed_at: string;
  watches: PathwayMonitorWatch[];
  total_watches: number;
  total_watches_open: number;
  total_watches_acknowledged: number;
  total_watches_actioned: number;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.patient = new Patient(item.patient);
    this.care_module = item.patient_pathway?.care_module
      ? new CareModule(item.patient_pathway.care_module)
      : undefined;
    this.patient_pathway_uid = item.patient_pathway?.id;
    this.surgery_date = item.surgery_date;
    this.discharge_date = item.discharge_date;

    // Overview
    this.monitors = item.monitors?.map(monitor => new Monitor(monitor));

    //   Details
    this.title = item.title;
    this.message = item.message;
    this.changed_at = item.changed_at;
    this.watches = item.watches?.map(watch => new PathwayMonitorWatch(watch));
    this.total_watches = item.total_watches;
    this.total_watches_open = item.total_watches_open || 0;
    this.total_watches_acknowledged = item.total_watches_acknowledged || 0;
    this.total_watches_actioned = item.total_watches_actioned || 0;
  }

  get titleTranslationKey() {
    return `shared.${this.title?.region}.${this.title?.key}`;
  }

  get messageTranslationKey() {
    return `shared.${this.message?.region}.${this.message?.key}`;
  }
}
