<div class="layout-header-pusher header-title-only"></div>

<div class="layout-header header-title-only">
  <div class="header-content">
    <div class="container">
      <h5>{{ 'pages.default.responses.responses' | translate }}</h5>
    </div>
  </div>
</div>

<div class="disclaimer px-2 py-1 bg-warning-subtle">
  <p
    class="extra-small mb-0 text-warning-emphasis font-weight-semibold">{{ 'pages.default.responses.disclaimer' | translate }}</p>
</div>

<div class="body-content">
  <!--  -->
  <!-- Filters - Start -->
  <!--  -->
  <div class="container pb-4">
    <div class="d-flex justify-content-between align-items-end">
      <div class="filters-group">

        <div class="form-group form-group-search">
          <label for="searchPatientLastName">{{ 'form.labels.patient_last_name' | translate }}</label>
          <input
            type="search"
            id="searchPatientLastName"
            class="form-control form-control-sm"
            [placeholder]="'form.labels.search' | translate"
            [(ngModel)]="filters.patientLastName"
            (ngModelChange)="patientLastNameSearch.next($event)"
          />
        </div>

        <div class="form-group">
          <app-select
            id="responses-select-pathway"
            [label]="('general.pathways' | translate)"
            [selectClass]="'ng-select-sm'"
            [clearable]="true"
            [searchable]="true"
            [hideSelected]="false"
            [multiple]="true"
            [items]="careModules"
            [bindValue]="'value'"
            [loading]="isLoadingCareModules"
            [(ngModel)]="filters.careModuleUids"
            (open)="getCareModules()"
            (ngModelChange)="onFilterChange()"

            [asFilter]="true"
            [showCheckboxes]="true"
          ></app-select>
        </div>

        @if (filtersAreSet) {
          <div class="divider"></div>

          <button type="reset" class="btn btn-link" id="dashboard-action-reset-filters"
                  (click)="actionResetFilters()">
            {{ 'action.reset' | translate }}
          </button>
        }
      </div>

      @if (totalElements) {
        <p class="mb-0 small fw-medium text-muted">
          {{ totalElements }} {{ 'pages.default.responses.responses' | translate }}
        </p>
      }
    </div>
  </div>
  <!--  -->
  <!-- Filters - End -->
  <!--  -->

  <div class="container">
    @if (isLoading) {
      <app-page-loader></app-page-loader>
    } @else {
      @for (pathwayMonitor of pathwayMonitors; track pathwayMonitor.patient.getFullName()) {
        <div class="response-item card p-2">
          <div class="patient-info">
            <div class="row gx-0">
              <div class="col-4">
                <div class="d-flex align-items-center">
                  <app-avatar [profilePicture]="pathwayMonitor.patient?.profile_picture"></app-avatar>

                  <div class="details-wrapper m-start-2">
                    <p class="title">
                      {{ pathwayMonitor.patient.getFullName() }}
                    </p>

                    <p class="small text-muted">
                      {{ pathwayMonitor.patient?.date_of_birth | timeZoneDate:dateFormat?.format }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-3">
                <p class="label extra-small text-muted">{{ 'pages.default.responses.pathway' | translate }}</p>
                <p class="small font-weight-semibold">
                  {{ pathwayMonitor.care_module.translationKey | translate }}
                </p>
              </div>

              <div class="col-2">
                <p class="label extra-small text-muted">
                  {{ 'pages.default.responses.days_since_surgery' | translate }}
                </p>

                <p class="small font-weight-semibold">
                  {{ daysSinceSurgery(pathwayMonitor) }}
                </p>
              </div>

              <div class="col-2">
                <p
                  class="label extra-small text-muted">{{ 'pages.default.responses.days_since_discharge' | translate }}</p>
                <p class="small font-weight-semibold">
                  {{ daysSinceDischarge(pathwayMonitor) }}
                </p>
              </div>

              <div class="col-1">
                <div class="d-flex justify-content-end align-items-center h-100">
                  <a class="btn btn-tertiary btn-icon"
                     [routerLink]="['/patient/', pathwayMonitor.patient.uid, pathwayMonitor.patient_pathway_uid, 'query-lists']"
                     [queryParams]="{
                     filter: 'QUESTIONNAIRE',
                     'back-to-url': router.url
                     }">
                    <span [inlineSVG]="'./assets/svg/information-outline-24.svg'"
                          class="rtl-mirrored-inline-svg"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <hr class="my-2">

          <div class="monitor">
            @for (monitor of pathwayMonitor.monitors; track monitor.monitor_id) {
              <div class="monitor-item">
                <div class="row gx-0 align-items-center">
                  <div class="col-7">
                    <p class="mb-0 font-weight-semibold">{{ monitor.titleTranslationKey | translate }}</p>
                  </div>

                  <div class="col-2">
                    <div class="watches d-inline-flex">
                      <div class="watch">
                        <span [inlineSVG]="'./assets/svg/watch-new.svg'"
                              class="rtl-mirrored-inline-svg watch-new"
                              [popover]="'pages.default.responses.monitor.new_watches' | translate"
                              triggers="mouseenter:mouseleave"></span>

                        <span class="watch-value">{{ monitor.total_watches_open }}</span>
                      </div>

                      <div class="watch">
                        <span [inlineSVG]="'./assets/svg/watch-acknowledged.svg'"
                              class="rtl-mirrored-inline-svg watch-acknowledged"
                              [popover]="'pages.default.responses.monitor.acknowledged_watches' | translate"
                              triggers="mouseenter:mouseleave"></span>

                        <span class="watch-value">{{ monitor.total_watches_acknowledged }}</span>
                      </div>

                      <div class="watch">
                        <span [inlineSVG]="'./assets/svg/watch-actioned.svg'"
                              class="rtl-mirrored-inline-svg watch-actioned"
                              [popover]="'pages.default.responses.monitor.actioned_watches' | translate"
                              triggers="mouseenter:mouseleave"></span>

                        <span class="watch-value">{{ monitor.total_watches_actioned }}</span>
                      </div>

                    </div>
                  </div>

                  <div class="col-2">
                    <p class="mb-0 d-inline" [popover]="'pages.default.responses.monitor.first_reported' | translate"
                       triggers="mouseenter:mouseleave">
                      {{ monitor.oldest_non_actioned_watch?.added_at | timeAgo:locale }}
                    </p>
                  </div>

                  <div class="col-1">
                    <div class="d-flex justify-content-end">
                      <a class="btn btn-tertiary btn-icon m-start-auto"
                         [routerLink]="['/responses', monitor.monitor_id]">
                        <span [inlineSVG]="'./assets/svg/arrow-right-24.svg'"
                              class="rtl-mirrored-inline-svg"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              @if (!$last) {
                <hr class="my-2">
              }
            } @empty {
              <p class="small text-muted">{{ 'pages.default.responses.no_responses_found' | translate }}</p>
            }
          </div>
        </div>

        @if (!$last) {
          <div class="my-2"></div>
        }
      } @empty {
        <div class="empty-state my-5 my-lg-7">
          <span [inlineSVG]="'./assets/svg-color/no-notifications-graphic.svg'"></span>
          <h6 class="h7">{{ 'pages.default.responses.no_responses_yet' | translate }}</h6>
        </div>
      }
    }
  </div>
</div>
